<template>
    <div class="n-upload-box"
        :style="`
            width: ${typeof width === 'number' ? width+'px' : width};
            height: ${typeof height === 'number' ? height+'px' : height};
        `"
        @mouseover="iconColor = '#503cff'; "
        @mouseleave="iconColor = '#999'; "
    >
        <plus v-if="src === ''" theme="outline" :size="iconSize" :fill="iconColor"/>
        <input 
            class="upload-input" 
            type="file" name="file" 
            accept="image/png,image/gif,image/jpeg"
            @change="change" 
        />
        <div class="upload-thumb-box" v-if="src !== ''">
            <img class="thumb" :src="src" />
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Plus, CheckSmall, Close } from '@icon-park/vue-next';

@Options({
    components: {
        Plus, CheckSmall, Close
    },
    props: {
        action: {
            type: String,
            default: ''
        },
        headers: {
            type: Object,
            default: () => {}
        },
        width: {
            type: [String, Number],
            default: 100
        },
        height: {
            type: [String, Number],
            default: 100
        },
        iconSize: {
            type: [String, Number],
            default: 30
        },
        autoUpload: {
            type: Boolean,
            default: false
        },
        // oss配置
        region: {
            type: String,
            default: 'oss-cn-beijing'
        },
        bucket: {
            type: String,
            default: ''
        },
        src: {
            type: String,
            default: ''
        }
    },
    data(){
        return{
            iconColor: '#999',
            file: {},
            client: '',
            status: '',
            uploaded: '',
        }
    },
    emits: ['update:src'],
    methods: {
        change(e: any){
            this.file = e.target.files[0];
            // this.src = URL.createObjectURL(this.file);
            const reader = new FileReader(), that = this;
            reader.readAsDataURL(this.file);
            reader.onloadend = function() {
                // that.src = this.result;
                console.log(that)
                that.$emit('update:src', this.result);
            };
        },
        uploadToOss(file: any){
            const that = this;
            const fileName = this.file.name;
        },
    }
})

export default class NUpload extends Vue {}
</script>

<style lang="scss" scoped>
.n-upload-box{
    @extend .flex-column-center;
    position: relative;
    cursor: pointer;
    border-radius: $radius;
    border: 1px dashed $border-color-dark;
    background-color: $white;
    &:hover{
        border-color: $primary;
        background-color: $primary-light;
    }
    .upload-input{
        z-index: 9;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }
    .upload-thumb-box{
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        .thumb{
            width: 100%;
            // height: 100%;
            border-radius: $radius;
            overflow: hidden;
        }
    }
}
</style>