<template>
    <div class="n-submenu">
        <div class="submenu-title" v-if="menu.children && menu.children.length > 0"
            :class="$route.path === menu.path ? 'current' : ''"
            @click="show = !show;"
        >
            <div class="menu-container">
                <span class="text">{{ menu.meta.title }}</span>
            </div>
            <down 
                class="arrow" 
                :class="show ? 'show' : 'hide'" 
                theme="outline" size="14" fill="#999" 
            />
        </div>
        <div class="submenu-title" v-else
            :class="$route.path === menu.path ? 'current' : ''"
            @click="hanlderClick(menu)"
        >
            <div class="menu-container">
                <span class="text">{{ menu.meta.title }}</span>
            </div>
        </div>
        <div
            v-if="menu.children && menu.children.length > 0" 
            class="submenu-child" 
            :style="`height:${show ? childMenuHeight*menu.children.length : 0}px`"
        >
            <template v-for="(item, index) in menu.children" :key="index">
                <div 
                    class="child-item"
                    :class="$route.path === item.path ? 'current' : ''"
                    ref="childMenu" 
                    @click="hanlderClick(item)"
                >
                    - {{ item.meta.title }}
                </div>
            </template>
        </div>
    </div>
    <!-- <down theme="outline" size="36" fill="#333"/> -->
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Down } from '@icon-park/vue-next';

@Options({
    components: {
        Down
    },
    props: {
        menu: {
            type: Object,
            default: () => []
        }
    },
    data(){
        return {
            show: false,
            childMenuHeight: 0
        }
    },
    watch: {
        show(){
            if(this.menu.children && this.menu.children.length > 0)
                this.childMenuHeight = this.$refs['childMenu'].offsetHeight;
        }
    },
    methods: {
        hanlderClick(item: any){
            console.log(item)
            if(item){
                this.$router.push({ path: item.path })
            }
        }
    }
})

export default class NSubmenu extends Vue {}
</script>

<style lang="scss" scoped>
.n-submenu{
    @extend .flex-column;
    width: 100%;
    .submenu-title{
        @extend .flex-row-between;
        cursor: pointer;
        height: $default-size;
        padding: 0 10px;
        font-size: 14px;
        .menu-container{
            @extend .flex-row;
            width: 100%;
        }
        .text, .arrow{
            @extend .flex-column-middle;
        }
        .arrow{
            &.show{
                transform: rotate(180deg);
            }
            &.hide{
                transform: rotate(0);
            }
        }
        &:hover{
            color: $primary;
        }
        &.current{
            background-color: $primary-light;
            color: $primary;
        }
    }
    .submenu-child{
        @extend .flex-column;
        transform-origin: 0 0;
        overflow: hidden;
        .child-item{
            cursor: pointer;
            padding: 10px 10px 10px 15px;
            text-align: left;
            font-size: 12px;
            color: $grey;
            &:hover{
                color: $primary;
            }
            &.current{
                background-color: $primary-light;
                color: $primary;
            }
        }
    }
}
</style>