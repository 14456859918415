<template>
    <div class="date-picker-container">

        <div class="date-picker-input" @click="showPanel = !showPanel">{{ checkedVal }}</div>
        <div class="date-picker-panel" :class="showPanel ? 'show' : 'hide'" :style="`z-index:${zIndex}`">
            <div class="year-box">
                <div class="left-box">
                    <div class="year-btn" @click="state.year -= 1">
                        <double-left theme="filled" size="20" fill="#333" :strokeWidth="2"/>
                    </div>
                    <div class="month-btn" @click="preMonth">
                        <left theme="filled" size="20" fill="#333" :strokeWidth="2"/>
                    </div>
                </div>
                <div class="center-box">
                    {{ `${state.year}年${state.month}月` }}
                </div>
                <div class="right-box">
                    <div class="month-btn" @click="nextMonth">
                        <right theme="filled" size="20" fill="#333" :strokeWidth="2"/>
                    </div>
                    <div class="year-btn" @click="state.year += 1">
                        <double-right theme="filled" size="20" fill="#333" :strokeWidth="2"/>
                    </div>
                </div>
            </div>
            <div class="date-box">
                <div class="weeks">
                    <span>日</span>
                    <span>一</span>
                    <span>二</span>
                    <span>三</span>
                    <span>四</span>
                    <span>五</span>
                    <span>六</span>
                </div>
                <div class="days">
                    <template v-for="(item, index) in currentMonthArr" :key="index">
                        <span 
                            :class="[
                                item.month !== state.month ? 'grey' : '',
                                item.year === checkedDate.year && 
                                item.month === checkedDate.month && 
                                item.day === checkedDate.day ? 'active' : '',
                                item.year === now.year && item.month === now.month && item.day === now.day ? 'now' : ''
                            ]"
                            @click="chooseDate(item); showPanel = false"
                        >
                            {{ item.day }}
                        </span>
                    </template>
                </div>
            </div>
        </div>
        <div class="date-picker-mask" v-if="showPanel" @click="showPanel = false"></div>

    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Left, Right, DoubleLeft, DoubleRight } from '@icon-park/vue-next';

@Options({
    components: {
        Left, Right, DoubleLeft, DoubleRight
    },
    props: {
        date: {
            type: Object,
            default: {
                year: '',
                month: '',
                day: '',
                week: ''
            }
        },
        zIndex: {
            type: Number,
            default: 996
        }
    },
    data(){
        return {
            state: {
                year: '',
                month: '',
                day: '',
            },
            now: {},
            checkedDate: {},
            currentMonthArr: [],
            showPanel: false
        }
    },
    watch: {
        'state':{
            handler(nVal){
                this.currentMonthArr = this.getMonthArr(nVal.year, nVal.month);
            },
            deep: true
        },
        'date': {
            handler(){
                if(this.date.year === ''){
                    this.now = this.checkedDate = {
                        year: this.state.year,
                        month: this.state.month,
                        day: this.state.day
                    };
                } else {
                    this.checkedDate = this.state = this.date;
                }
            },
            deep: true
        }
    },
    computed: {
        checkedVal(){
            return `${this.checkedDate.year}年${this.checkedDate.month}月${this.checkedDate.day}日`;
        }
    },
    emits: ['change', 'update:date'],
    mounted(){
        let date = new Date();
        this.state = {
            year: date.getFullYear(),
            month: date.getMonth()+1,
            day: date.getDate()
        };
        
        
        this.chooseDate(this.state);
    },
    methods: {
        getWeek(date: string) {
            return new Date(Date.parse(date)).getDay();
        },
        getMonthArr(year: number, month: number){
            let days = new Date(year, month, 0).getDate(),
                preYear, preMonth, preDays, 
                sufYear, sufMonth, sufDays,
                preArr = [], sufArr = [],
                arr = [], 
                firstWeek = this.getWeek(`${year}-${month}-01`),
                lastWeek = this.getWeek(`${year}-${month}-${days}`),
                i;
            if(month === 12){
                preYear = year;
                preMonth = month-1;
                preDays = new Date(year, month-1, 0).getDate();

                sufYear = year+1;
                sufMonth = 1;
                sufDays = new Date(year+1, 1, 0).getDate();
            } else if(month === 1){
                preYear = year-1;
                preMonth = 12;
                preDays = new Date(year-1, 12, 0).getDate();

                sufYear = year;
                sufMonth = month+1;
                sufDays = new Date(year, month+1, 0).getDate();
            } else {
                preYear = sufYear = year;
                preMonth = month-1;
                sufMonth = month+1;
                preDays = new Date(year, month-1, 0).getDate();
                sufDays = new Date(year, month+1, 0).getDate();
            }

            // 需要补充的上个月的数组
            for(i = 0; i<firstWeek; i++){
                preArr.push({
                    year: preYear,
                    month: preMonth,
                    day: preDays-i,
                    week: this.getWeek(`${preYear}-${preMonth}-${preDays-i}`)
                })
            }

            // 需要补充的下个月的数组
            if(lastWeek !== 0){
                for(i = 0; i<6-lastWeek; i++){
                    sufArr.push({
                        year: sufYear,
                        month: sufMonth,
                        day: i+1,
                        week: this.getWeek(`${sufYear}-${sufMonth}-${i+1}`)
                    })
                }
            }

            for(i = 0; i<days; i++){
                arr.push({
                    year: year,
                    month: month,
                    day: i+1,
                    week: this.getWeek(`${year}-${month}-${i+1}`)
                })
            }
            
            return [
                ...preArr.reverse(),
                ...arr,
                ...sufArr
            ];
        },
        chooseDate(item: any){
            this.checkedDate = item;
            this.$emit('change', this.checkedDate);
            this.$emit('update:date', this.checkedDate);
        },
        nextMonth(){
            if(this.state.month === 12){
                this.state.month = 1;
                this.state.year += 1;
            } else this.state.month += 1;
        },
        preMonth(){
            if(this.state.month === 1){
                this.state.month = 12;
                this.state.year -= 1;
            } else this.state.month -= 1;
        }
    }
})

export default class NDatePicker extends Vue {}
</script>

<style lang="scss" scoped>
.date-picker-container{
    position: relative;
    .date-picker-input{
        @extend .flex-column-middle;
        height: 30px;
        padding: 0 10px;
        cursor: pointer;
        border-radius: $radius;
        border: 1px solid $border-color;
        background-color: $white;
    }
    .date-picker-panel{
        @extend .flex-column;
        z-index: 2;
        position: absolute;
        width: 294px;
        border-radius: $radius;
        background-color: $white;
        box-shadow: $shadow;
        transform-origin: 0 0;

        .year-box{
            @extend .flex-row;
            justify-content: space-between;
            padding: 5px 0 15px;
            .left-box,
            .right-box{
                @extend .flex-row;
            }
            .year-btn,
            .month-btn{
                @extend .flex-column-center;
                cursor: pointer;
                width: 30px;
                height: 30px;
            }
            .center-box{
                @extend .flex-column-center;
                height: 30px;
                font-size: 14px;
            }
        }

        .date-box{
            @extend .flex-column;
            .weeks{
                @extend .flex-row-center;
                border-bottom: 1px solid $border-color;
                span{
                    @extend .flex-column-center;
                    width: 42px;
                    height: 30px;
                }
            }
            .days{
                @extend .flex-row;
                flex-wrap: wrap;
                span{
                    @extend .flex-column-center;
                    cursor: pointer;
                    width: 24px;
                    height: 24px;
                    margin: 9px;
                    border-radius: $radius-circle;
                    font-size: 13px;
                    &.grey{
                        color: $grey-light;
                    }
                    &.active{
                        background-color: $primary;
                        color: $white;
                        &.now{
                            color: $white;
                        }
                        &:hover{
                            color: $white;
                            background-color: $primary;
                        }
                    }
                    &.now{
                        color: $primary;
                    }
                    &:hover{
                        color: $primary;
                        background-color: $primary-light;
                    }
                }
            }
        }

        &.show{
            transform: scale3d(1,1,1);
        }
        &.hide{
            transform: scale3d(1,0,1);
        }
    }
    .date-picker-mask{
        z-index: 1;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}
</style>