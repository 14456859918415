
import { Options, Vue } from 'vue-class-component';
import { RightOne } from '@icon-park/vue-next';
import NTreeItem from './TreeItem.vue';

@Options({
    components: {
        RightOne, NTreeItem
    },
    props: {
        options: {
            type: Array,
            default: () => []
        }
    },
    data(){
        return {
            tree: []
        }
    },
    mounted(){
        this.tree = this.options;
    },
    methods: {
        handlerCheck(item: any){
            console.log(item)
        },
        handlerChild(item: any){
            console.log(item)
        }
    }
})

export default class NTree extends Vue {}
