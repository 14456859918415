
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        type: {
            type: String,
            default: ''
        },
        closeable: {
            type: Boolean,
            default: true
        },
        size: {
            type: String,
            default: ''
        },
        // 可选plain镂空，default浅色背景，fill实心填充
        theme: {
            type: String,
            default: 'plain'
        }
    },
    emits: ['click', 'close'],
    data(){
        return{
            show: true
        }
    },
    methods: {
        handlerClick(){
            this.$emit('click');
        },
        handlerClose(){
            this.$emit('close');
            this.show = false;
        }
    }
})

export default class NTag extends Vue {}
