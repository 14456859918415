
import { Options, Vue } from 'vue-class-component';
import { Close } from '@icon-park/vue-next';

@Options({
    components: {
        Close
    },
    props: {
        src: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: 'Avatar'
        },
        size: {
            type: Number,
            default: 30
        },
        shape: {
            type: String,
            default: 'circle'
        }
    },
    data(){
        return {
            previewShow: false,
            imgClient: {
                width: 0,
                height: 0
            }
        }
    },
    mounted(){
        let img = new Image(), that = this;
        img.src = this.src;
        img.onload = () => {
            that.imgClient.width = img.width;
            that.imgClient.height = img.height;
        }
    },
})

export default class NAvatar extends Vue {}
