<template>
    <div class="n-checkbox" @click="$emit('update:checked', !checked)">
        <div class="checkbox" :class="checked ? 'checked' : ''">
            <i class="n-icon n-select"></i>
        </div>
        <div class="slot-container">
            <slot></slot>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        checked: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            isChecked: false
        }
    },
    emits: ['update:checked']
})

export default class NCheckbox extends Vue {}
</script>

<style lang="scss" scoped>
.n-checkbox{
    @extend .flex-row;
    cursor: pointer;
    height: 20px;
    .checkbox{
        @extend .flex-column-center;
        width: 15px;
        height: 15px;
        margin: 2.5px 0;
        border-radius: $radius;
        border: 1px solid $grey;
        .n-icon{
            // @extend .flex-column-center;
            opacity: 0;
            width: 100%;
            height: 100%;
            color: transparent;
        }

        &.checked{
            border-color: $primary;
            background-color: $primary;
            .n-icon{
                opacity: 1;
                color: white;
            }
        }
    }
    .slot-container{
        @extend .flex-column-middle;
        margin-left: 3px;
    }
}
</style>