<template>
    <div class="n-notification-container" 
        :class="show ? 'show' : 'hide'"
        @mousemove="canClose = false"
        @mouseout="canClose = true; notifyClose"
    >
        <div class="notify-icon" v-if="$slots.icon">
            <slot name="icon"></slot>
        </div>
        <div class="notify-container">
            <h4 class="title">{{ title }}</h4>
            <div class="content">
                {{ content }}
            </div>
        </div>
        <div class="close-icon" 
            @mouseover="closeColor='#222'" 
            @mouseout="closeColor='#999'"
            @click="$emit('update:show', false)"
        >
            <close class="icon" theme="outline" size="14" :fill="closeColor"></close>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Close } from '@icon-park/vue-next';

@Options({
    components: {
        Close
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        duration: {
            type: Number,
            default: 5000
        },
        type: {
            type: String,
            default: ''
        },
        zIndex: {
            type: [String, Number],
            default: 9999
        },
        title: {
            type: String,
            default: '提示'
        },
        content: {
            type: String,
            default: '通知消息内容'
        }
    },
    emits: ['update:show'],
    data(){
        return{
            closeColor: '#999',
            canClose: true
        }
    },
    watch: {
        show(){
            if(this.show) this.notifyClose();
        }
    },
    methods: {
        notifyClose(){
            if(this.duration)
                setTimeout(() => {
                    if(this.canClose) this.$emit('update:show', false);
                }, this.duration);
        }
    }
})

export default class NNotification extends Vue {}
</script>

<style lang="scss" scoped>
.n-notification-container{
    @extend .flex-row;
    z-index: 9999;
    position: fixed;
    top: 8%;
    width: 320px;
    text-align: left;
    border-radius: $radius-big;
    background-color: $white;
    box-shadow: $shadow;
    .notify-icon{
        padding: 10px 0 10px 10px;
    }
    .notify-container{
        .title{
            margin: 0;
            padding: 10px;
            font-size: 14px;
        }
        .content{
            padding: 0 10px 10px;
        }
    }
    .close-icon{
        @extend .flex-column-center;
        position: absolute;
        right: 0;
        top: 0;
        padding: 10px;
        cursor: pointer;
    }
    &.show{
        right: 2%;
    }
    &.hide{
        right: -340px;
    }
}
</style>