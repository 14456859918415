
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        show: {
            type: Boolean,
            default: false
        },
        width: {
            type: Number,
            default: 360
        },
        height: {
            type: [Number, String],
            default: 220
        },
        top: {
            type: String,
            default: '50%'
        },
        left: {
            type: String,
            default: '50%'
        },
        zIndex: {
            type: [Number, String],
            default: 999
        }
    },
    data(){
        return{
            modalClient: {
                width: '',
                height: ''
            },
            isShow: false
        }
    },
    emits: ['update:show', 'close'],
    watch: {
        show(){
            setTimeout(() => {
                this.isShow = this.show;
            },100);
        }
    },
    mounted(){
        this.isShow = this.show;
    }
})

export default class NModal extends Vue {}
