export default {
    path: '/dashboard',
    redirect: '/dashboard/analysis',
    meta: {
        title: '仪表盘'
    },
    component: () => import('@/views/dashboard/Layout.vue'),
    children: [
        {
            path: 'analysis',
            meta: {
                title: '分析页'
            },
            component: () => import('@/views/dashboard/Analysis.vue')
        }, {
            path: 'monitor',
            meta: {
                title: '监控页'
            },
            component: () => import('@/views/dashboard/Monitor.vue')
        }, {
            path: 'workplace',
            meta: {
                title: '工作台'
            },
            component: () => import('@/views/dashboard/Workplace.vue')
        }
    ]
}