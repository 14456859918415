<template>
    <div class="n-textarea">
        <textarea 
            class="n-textarea-container" 
            :placeholder="placeholder"
            v-model="val"
            :readonly="readonly"
            :autofocus="autofocus"
            :maxlength="maxlength"
            :cols="cols" :rows="rows"
            @change="$emit('update:value', val);"
        ></textarea>
        <span class="number-box">{{ val.length+'/'+maxlength+'字' }}</span>
    </div>
</template>

<script lang="ts">
import { reactive, ref } from 'vue'
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        value: {
            type: String,
            default: ''
        },
        autofocus: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        cols: {
            type: Number,
            default: 8
        },
        rows: {
            type: Number,
            default: 4
        },
        maxlength: {
            type: Number,
            default: 140
        },
        placeholder: {
            type: String,
            default: '输入内容'
        }
    },
    watch:{
        value(nVal){
            if(nVal !== null)
                this.val = this.value;
        }
    },
    emits: ['update:value'],
    data(){
        return {
            val: ''
        }
    }
})

export default class NTextarea extends Vue{}
</script>

<style lang="scss" scoped>
.n-textarea{
    position: relative;
    width: 100%;
    background-color: $white;
    &-container{
        width: 100%;
        line-height: 20px;
        padding: 5px 10px;
        resize: none;
        border-radius: $radius;
        border: 1px solid $border-color;
        font-family: Arial, Helvetica, sans-serif;
    }
    .number-box{
        position: absolute;
        right: 8px;
        bottom: 8px;
        color: $grey;
    }
}
</style>