<template>
    <li class="n-tree-item">
        <div class="item"
            :class="checked ? 'checked' : ''"
            @click="toggle"
        >
            <span class="icon" :class="isOpen ? 'open' : ''">
                <right-one v-if="hasChildren" theme="filled" size="14" fill="#999"/>
            </span>
            <span class="name">{{ data.name }}</span>
        </div>
        <ul class="n-tree-item-child" 
            :class="opened ? 'show' : 'hide'"
            v-if="hasChildren"
            v-show="isOpen"
        >
            <n-tree-item 
                v-for="(child, cIndex) in data.children" 
                :key="cIndex" :data="child" 
                @check="childClick"
            />
        </ul>
    </li>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { RightOne } from '@icon-park/vue-next';

@Options({
    components: {
        RightOne
    },
    props: {
        data: {
            type: [ Object, Array ],
            require: true
        }
    },
    data(){
        return {
            isOpen: false,
            opened: false,
            checked: false
        }
    },
    computed: {
        hasChildren(){
            return this.data.children ? true : false;
        }
    },
    emits: ['check', 'child-check'],
    methods: {
        toggle(){
            this.checked = !this.checked;
            this.$emit('check', this.data);
            if(this.hasChildren) {
                if(this.isOpen){
                    this.opened = false;
                    setTimeout(() => {
                        this.isOpen = false;
                    }, 220);
                } else {
                    this.isOpen = true;
                    setTimeout(() => {
                        this.opened = true;
                    },1);
                }
            }
        },
        childClick(item: any){
            this.$emit('child-check', item);
        }
    }
})

export default class NTreeItem extends Vue {}
</script>

<style lang="scss" scoped>
.n-tree-item{
    @extend .flex-column;
    cursor: pointer;
    .item{
        @extend .flex-row;
        height: 33px;
        .icon, .name{
            @extend .flex-column-middle;
        }
        .icon{
            align-items: center;
            text-align: center;
            width: 17px;
            transform: rotate(0);
            &.open{
                transform: rotate(90deg);
            }
        }
    }
    &-child{
        margin-left: 14px;
        transform-origin: 0 0;
        &.show{
            transform: scale3d(1,1,1);
        }
        &.hide{
            transform: scale3d(1,0,1);
        }
    }
}
</style>