<template>
    <div 
        class="n-switch-box"
        :class="[type, size, value ? 'active' : 'inactive']"
        :style="``" 
    >
        <div class="inactive-text text" v-if="inactiveText !== ''">{{ inactiveText }}</div>
        <div class="switch-container"
            @click="handlerClick"
        >
            <div class="point"></div>
        </div>
        <div class="active-text text" 
            :class="type" 
            v-if="activeText !== ''"
        >{{ activeText }}</div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        value: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'primary'
        },
        size: {
            type: String,
            default: 'large'
        },
        activeText: {
            type: String,
            default: ''
        },
        activeColor: {
            type: String,
            default: ''
        },
        inactiveText: {
            type: String,
            default: ''
        },
        inactiveColor: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:value'],
    methods: {
        handlerClick(){
            if(!this.disabled){
                this.$emit('update:value', !this.value)
            }
        }
    }
})

export default class NSwitch extends Vue {}
</script>

<style lang="scss" scoped>
.n-switch-box{
    @extend .flex-row;
    .switch-container{
        cursor: pointer;
        position: relative;
        margin: 0 10px;
        border-radius: $radius-round;
        background-color: $grey-light;
        .point{
            position: absolute;
            top: 2px;
            left: 2px;
            border-radius: $radius-circle;
            background-color: $white;
        }
    }
     &.active{
        // type
        &.primary{
            .switch-container{
                background-color: $primary;
            }
            .active-text{
                color: $primary;
            }
        }
        &.success{
            .switch-container{
                background-color: $success;
            }
            .active-text{
                color: $success;
            }
        }
        &.warning{
            .switch-container{
                background-color: $warning;
            }
            .active-text{
                color: $warning;
            }
        }
        &.danger{
            .switch-container{
                background-color: $danger;
            }
            .active-text{
                color: $danger;
            }
        }
    }

    // size
    &.large{
        .switch-container{
            height: 34px;
            width: 66px;
            .point{
                width: 30px;
                height: 30px;
            }
        }
        &.active{
            .point{
                left: 34px;
            }
        }
    }
    &.medium{
        .switch-container{
            height: 24px;
            width: 46px;
            .point{
                width: 20px;
                height: 20px;
            }
        }
        &.active{
            .point{
                left: 24px;
            }
        }
    }
    &.small{
        .switch-container{
            height: 16px;
            width: 30px;
            .point{
                width: 12px;
                height: 12px;
            }
        }
        &.active{
            .point{
                left: 16px;
            }
        }
    }

    .text{
        @extend .flex-column-middle;
    }
}
</style>