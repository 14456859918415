export default {
    path: '/recommends',
    redirect: '/recommends/home-carousel',
    meta: {
        title: '推荐位管理'
    },
    component: () => import('@/views/recommend/Recommend.vue'),
    children: [
        {
            path: '/recommends/home-carousel',
            name: 'Home Carousel',
            meta: {
                title: '首页轮播图'
            },
            component: () => import('@/views/recommend/Carousel.vue')
        },{
            path: '/recommends/home-carousel/create',
            name: 'Home Carousel Create',
            meta: {
                title: '新建轮播图'
            },
            component: () => import('@/views/recommend/CarouselCreate.vue')
        },{
            path: '/recommends/home-carousel/edit/:id',
            name: 'Home Carousel Editor',
            meta: {
                title: '编辑轮播图'
            },
            component: () => import('@/views/recommend/CarouselEdit.vue')
        },{
            path: '/recommends/home-about',
            name: 'Home About',
            meta: {
                title: '首页关于'
            },
            component: () => import('@/views/recommend/About.vue')
        },{
            path: '/recommends/home-service',
            name: 'Home Service',
            meta: {
                title: '首页服务'
            },
            component: () => import('@/views/recommend/Service.vue')
        }
    ]
}