export default {
    path: '/content',
    meta: {
        title: '内容管理'
    },
    component: () => import('@/views/content/Layout.vue'),
    children: [
        {
            path: '/content/category/:id/pages/edit',
            meta: {
                title: '编辑单页面'
            },
            component: () => import('@/views/content/CategoryEdit.vue')
        },

        // 分类管理
        {
            path: '/content/category/create',
            meta: {
                title: '新建栏目'
            },
            component: () => import('@/views/content/CategoryCreate.vue')
        },{
            path: '/content/category/edit/:id',
            meta: {
                title: '编辑栏目'
            },
            component: () => import('@/views/content/CategoryEdit.vue')
        },

        // 文章管理
        {
            path: '/content/category/:id/articles',
            meta: {
                title: '文章列表'
            },
            component: () => import('@/views/content/Articles.vue')
        },{
            path: '/content/category/:id/articles/create',
            meta: {
                title: '新建文章'
            },
            component: () => import('@/views/content/ArticleCreate.vue')
        },{
            path: '/content/category/:id/articles/edit/:article_id',
            meta: {
                title: '编辑文章'
            },
            component: () => import('@/views/content/ArticleEdit.vue')
        },

        // 产品管理
        {
            path: '/content/category/:id/equipments',
            meta: {
                title: '设备列表'
            },
            component: () => import('@/views/content/Equipments.vue')
        },{
            path: '/content/category/:id/equipments/create',
            meta: {
                title: '新建设备'
            },
            component: () => import('@/views/content/EquipmentCreate.vue')
        },{
            path: '/content/category/:id/equipments/edit/:equipment_id',
            meta: {
                title: '编辑设备'
            },
            component: () => import('@/views/content/EquipmentEdit.vue')
        }
    ]
}