<template>
    <div class="time-picker-container">
        <div class="time-picker-input" @click="show = !show">
            {{ `${current.hour}时${current.minute}分${current.second}秒` }}
        </div>
        <div class="time-picker-panel" 
            :class="[
                show ? 'show' : 'hide',
                position
            ]"
            :style="`z-index:${zIndex}`"
        >
            <div class="panel-head">
                <span>时</span>
                <span>分</span>
                <span>秒</span>
            </div>
            <div class="panel-body">
                <n-scroll-view class="scroll-box" size="small" scroll-y>
                    <div class="time-scroll">
                        <template v-for="(item, index) in state.hour" :key="index">
                            <span 
                                :class="current.hour == item ? 'active' : ''"
                                @click="current.hour = item"
                            >{{ item }}</span>
                        </template>
                    </div>
                </n-scroll-view>
                <n-scroll-view class="scroll-box" size="small" scroll-y>
                    <div class="time-scroll">
                        <template v-for="(item, index) in state.minute" :key="index">
                            <span 
                                :class="current.minute == item ? 'active' : ''"
                                @click="current.minute = item"
                            >{{ item }}</span>
                        </template>
                    </div>
                </n-scroll-view>
                <n-scroll-view class="scroll-box" size="small" scroll-y>
                    <div class="time-scroll">
                        <template v-for="(item, index) in state.second" :key="index">
                            <span 
                                :class="current.second == item ? 'active' : ''"
                                @click="current.second = item"
                            >{{ item }}</span>
                        </template>
                    </div>
                </n-scroll-view>
            </div>
        </div>
        <div class="time-picker-mask" v-if="show" @click="show = false"></div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        time: {
            type: Object,
            default: {
                hour: '',
                minute: '',
                second: ''
            }
        },
        position: {
            type: String,
            default: 'bottom left'
        },
        zIndex: {
            type: Number,
            default: 996
        }
    },
    data(){
        return{
            state: {
                hour: [],
                minute: [],
                second: []
            },
            current: {
                hour: '00',
                minute: '00',
                second: '00'
            },
            show: false
        }
    },
    emits: ['change', 'update:time'],
    watch: {
        'current':{
            handler(nVal){
                this.$emit('change', nVal);
                this.$emit('update:time', nVal);
            },
            deep: true
        },
        'time': {
            handler(){
                if(this.time) this.current = this.time;
                else {
                    let date = new Date();
                    this.current = {
                        hour: date.getHours(),
                        minute: date.getMinutes(),
                        second: date.getSeconds()
                    }
                }
            },
            deep: true
        }
    },
    mounted(){
        for(let i=0; i<24; i++){
            if(i<10) this.state.hour.push('0'+i);
            else this.state.hour.push(i);
        }
        for(let i=0; i<60; i++){
            if(i<10) {
                this.state.minute.push('0'+i);
                this.state.second.push('0'+i);
            } else {
                this.state.minute.push(i);
                this.state.second.push(i);
            }
        }

        let date = new Date();
        this.current = {
            hour: date.getHours() < 10 ? `0${date.getHours()}` : date.getHours(),
            minute: date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes(),
            second: date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()
        }
    }
})

export default class NTimePicker extends Vue {}
</script>

<style lang="scss" scoped>
.time-picker-container{
    position: relative;
    .time-picker-input{
        @extend .flex-column-middle;
        height: 30px;
        padding: 0 10px;
        cursor: pointer;
        border-radius: $radius;
        border: 1px solid $border-color;
        background-color: $white;
    }
    .time-picker-panel{
        @extend .flex-column;
        z-index: 2;
        position: absolute;
        width: 220px;
        border-radius: $radius;
        background-color: $white;
        box-shadow: $shadow;
        transform-origin: 0 0;
        .panel-head{
            @extend .flex-row-center;
            span{
                @extend .flex-column-center;
                width: 50%; 
                height: 40px;
            }
        }
        .panel-body{
            @extend .flex-row-center;
            margin-bottom: 4px;
            .scroll-box{
                width: 73.3333px;
                height: 120px;
                .time-scroll{
                    @extend .flex-column;
                    span{
                        @extend .flex-column-center;
                        cursor: pointer;
                        height: 25px;
                        margin-left: 4px;
                        border-radius: $radius-round;
                        &:hover{
                            background-color: $primary-light;
                            color: $primary;
                        }
                        &.active{
                            background-color: $primary;
                            color: $white;
                        }
                    }
                }
            }
        }
        &.show{
            transform: scale3d(1,1,1);
        }
        &.hide{
            transform: scale3d(1,0,1);
        }

        &.bottom{
            top: 100%;
        }
        &.left{
            left: 0;
        }
        &.right{
            right: 0;
        }
    }
    .time-picker-mask{
        z-index: 1;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}
</style>