import {http} from '@/api/interceptor.ts';

function getSts(){
    return http({
        url: '/oss/sts'
    })
}
function getNextPage(url: string){
    return http({
        url: url
    })
}

function login(username: string, password: string){
    return http({
        url: '/auth/login',
        method: 'POST',
        data: {
            username: username,
            password: password,
            provider: 'admin',
            token_type: 'Web'
        }
    })
}

function checkUserExits(username: string, guard: string){
    return http({
        url: `/auth/checkUserExits/${guard}/${username}`
    })
}
function getUserInfo(){
    return http({
        url: '/auth/getInfo'
    })
}

function getUserChart(days: number){
    return http({
        url: `/users/chart/data/${days}`
    })
}
function getUsers(){
    return http({
        url: '/users'
    })
}
function createNewUser(data: object){
    return http({
        url: '/users',
        method: 'POST',
        data: data
    })
}
function getUserInfoById(id: number){
    return http({
        url: `/users/${id}`
    })
}
function destroyUser(id: number){
    return http({
        url: `/users/${id}`,
        method: 'DELETE'
    })
}
function updateUserInfoById(id: number, data: object){
    return http({
        url: `/users/${id}`,
        method: 'PUT',
        data: data
    })
}
// 新建或更新用户时异步检查name、email、phone是否已存在
function checkUserRepeat(type: string, label: string, value: string, id = 0){
    return http({
        url: '/users/checkRepeat',
        method: 'POST',
        data: {
            id: id,
            type: type,
            label: label,
            value: value
        }
    })
}


// 管理员管理
function getAdmins(){
    return http({
        url: '/admins'
    })
}
function createNewAdmin(data: object){
    return http({
        url: '/admins',
        method: 'POST',
        data: data
    })
}
function getAdminInfoById(id: number){
    return http({
        url: `/admins/${id}`
    })
}
function updateAdminInfoById(id: number, data: object){
    return http({
        url: `/admins/${id}`,
        method: 'PUT',
        data: data
    })
}
function destroyAdmin(id: number){
    return http({
        url: `/admins/${id}`,
        method: 'DELETE'
    })
}
// 新建或更新管理员时异步检查name、email、phone是否已存在
function checkAdminRepeat(type: string, label: string, value: string, id = 0){
    return http({
        url: '/admins/checkRepeat',
        method: 'POST',
        data: {
            id: id,
            type: type,
            label: label,
            value: value
        }
    })
}

// 获取guards
function getGuards(){
    return http({
        url: '/get/guards'
    })
}
// 权限管理
function getPermissions(){
    return http({
        url: '/permissions'
    })
}
function getPermissionInfoById(id: number){
    return http({
        url: `/permissions/${id}`
    })
}
function createNewPermission(data: object){
    return http({
        url: '/permissions',
        method: 'POST',
        data: data
    })
}
function updatePermissionById(id: number, data: object){
    return http({
        url: `/permissions/${id}`,
        method: 'PUT',
        data: data
    })
}
function destroyPermission(id: number){
    return http({
        url: `/permissions/${id}`,
        method: 'DELETE'
    })
}

// 获取guard_name下的所有权限
function getPermissionsByGuard(guard: string){
    return http({
        url: `/get/permissions/withGuard/${guard}`
    })
}
// 获取guard_name下的所有角色
function getRolesByGuard(guard: string){
    return http({
        url: `/get/roles/withGuard/${guard}`
    })
}
function getRoles(){
    return http({
        url: '/roles'
    })
}
function getRoleInfoById(id: number){
    return http({
        url: `/roles/${id}`
    })
}
function createNewRole(data: object){
    return http({
        url: '/roles',
        method: 'POST',
        data: data
    })
}
function updateRoleById(id: number, data: object){
    return http({
        url: `/roles/${id}`,
        method: 'PUT',
        data: data
    })
}
function destroyRole(id: number){
    return http({
        url: `/roles/${id}`,
        method: 'DELETE'
    })
}


// xuanrun 网站模块相关
function xrCategories(){
    return http({
        url: '/xr/categories'
    })
}
function xrCategoryShow(id: number){
    return http({
        url: `/xr/categories/${id}`
    })
}
function xrCategoryUpdate(id: number, data: object){
    return http({
        url: `/xr/categories/${id}`,
        method: 'PUT',
        data: data
    })
}
function xrStoreCategory(data: any){
    return http({
        url: `/xr/categories`,
        method: 'POST',
        data: data
    })
}
function xrDestroyCategory(id: number){
    return http({
        url: `/xr/categories/${id}`,
        method: 'DELETE'
    })
}

function xrArticles(cat_id: number){
    return http({
        url: `/xr/articles/list/${cat_id}`
    });
}
function xrArticleShow(id: number){
    return http({
        url: `/xr/articles/${id}`
    })
}
function xrArticleUpdate(id: number, data: object){
    return http({
        url: `/xr/articles/${id}`,
        method: 'PUT',
        data: data
    })
}
function xrStoreArticle(data: object){
    return http({
        url: `/xr/articles`,
        method: 'POST',
        data: data
    });
}
function xrDestroyArticle(id: number){
    return http({
        url: `/xr/articles/${id}`,
        method: 'DELETE'
    });
}

function xrEquipments(cat_id: number){
    return http({
        url: `/xr/equipments/list/${cat_id}`
    })
}
function xrEquipmentShow(id: number){
    return http({
        url: `/xr/equipments/${id}`
    })
}
function xrEquipmentUpdate(id: number, data: object){
    return http({
        url: `/xr/equipments/${id}`,
        method: 'PUT',
        data: data
    })
}
function xrStoreEquipment(data: object){
    return http({
        url: '/xr/equipments',
        method: 'POST',
        data: data
    });
}
function xrDeleteEquipment(id: number){
    return http({
        url: `/xr/equipments/${id}`,
        method: 'DELETE'
    })
}

// 推荐位管理
function xrHomeCarousels(){
    return http({
        url: '/xr/home-carousels'
    })
}
function xrHomeCarouselShow(id: number){
    return http({
        url: `/xr/home-carousels/${id}`
    })
}
function xrHomeCarouselUpdate(id: number, data: object){
    return http({
        url: `/xr/home-carousels/${id}`,
        method: 'PUT',
        data: data
    })
}
function xrStoreHomeCarousel(data: object){
    return http({
        url: '/xr/home-carousels',
        method: 'POST',
        data: data
    })
}
function xrDestroyHomeCarousel(id: number){
    return http({
        url: `/xr/home-carousels/${id}`,
        method: 'DELETE'
    })
}

function xrHomeAbout(){
    return http({
        url: '/xr/home-about/1'
    })
}
function xrHomeAboutUpdate(data: object){
    return http({
        url: `/xr/home-about/1`,
        method: 'PUT',
        data: data
    })
}

function xrHomeService(){
    return http({
        url: '/xr/home-service'
    })
}
function xrHomeServiceUpdate(id:number, data:object){
    return http({
        url: `/xr/home-service/${id}`,
        method: 'PUT',
        data: data
    })
}

// 留言板
function xrMessages(){
    return http({
        url: '/xr/messages'
    })
}
function xrMessageUpdate(id:number){
    return http({
        url: `/xr/messages/${id}`,
        method: 'PUT'
    })
}
function xrDeleteMessage(id: number){
    return http({
        url: `/xr/messages/${id}`,
        method: 'DELETE'
    })
}

// 设置
function xrSettingWebsite(){
    return http({
        url: '/xr/setting/website'
    })
}
function xrSettingWebsiteUpdate(data: object){
    return http({
        url: '/xr/setting/website',
        method: 'PUT',
        data: data
    })
}
function xrSettingContact(){
    return http({
        url: '/xr/setting/contact'
    })
}
function xrSettingContactUpdate(data: object){
    return http({
        url: '/xr/setting/contact',
        method: 'PUT',
        data: data
    })
}

export default {
    getSts, // 阿里云oss上传，服务端加密
    getNextPage, // 获取下一页
    login, // 用户登录
    checkUserExits, // 管理员
    getUserInfo,  // 获取登录用户信息

    // 用户管理
    getUserChart, // 获取用户增长趋势数组
    getUsers, // 获取用户列表
    createNewUser,
    getUserInfoById,
    destroyUser,
    updateUserInfoById,
    checkUserRepeat,

    // 管理员管理
    getAdmins,
    createNewAdmin,
    getAdminInfoById,
    updateAdminInfoById,
    destroyAdmin,
    checkAdminRepeat,

    // 权限管理
    getGuards,
    getPermissions,
    getPermissionInfoById,
    createNewPermission,
    updatePermissionById,
    destroyPermission,
    
    // 角色管理
    getPermissionsByGuard,
    getRolesByGuard,
    getRoles,
    getRoleInfoById,
    createNewRole,
    updateRoleById,
    destroyRole,

    xrCategories,
    xrCategoryShow,
    xrCategoryUpdate,
    xrStoreCategory,
    xrDestroyCategory,

    xrArticles,
    xrArticleShow,
    xrArticleUpdate,
    xrStoreArticle,
    xrDestroyArticle,

    xrEquipments,
    xrEquipmentShow,
    xrEquipmentUpdate,
    xrStoreEquipment,
    xrDeleteEquipment,

    xrHomeCarousels,
    xrStoreHomeCarousel,
    xrHomeCarouselShow,
    xrHomeCarouselUpdate,
    xrDestroyHomeCarousel,

    xrHomeAbout,
    xrHomeAboutUpdate,
    
    xrHomeService,
    xrHomeServiceUpdate,

    xrMessages,
    xrMessageUpdate,
    xrDeleteMessage,

    xrSettingWebsite,
    xrSettingWebsiteUpdate,
    xrSettingContact,
    xrSettingContactUpdate
}

