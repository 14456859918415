import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
// 仪表盘
import dashboard from '@/mock/routes/dashboard'
// 权限
import permissions from '@/mock/routes/permissions'
// 用户
import users from '@/mock/routes/users'
// 文件
import files from '@/mock/routes/files'

// 内容管理
import content from '@/mock/routes/content'
// 推荐位
import recommend from '@/mock/routes/recommend'
// 留言板
import message from '@/mock/routes/message'
// 设置
import setting from '@/mock/routes/setting'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'Login',
		meta: {
			title: '登录'
		},
		component: () => import('@/views/Login.vue')
	},
	{
		path: '/admin',
		redirect: '/home',
		name: 'Layout',
		component: () => import('@/views/Layout.vue'),
		children: [
			{
				path: '/home',
				meta: {
					title: '主页'
				},
				component: () => import('@/views/Home.vue')
			},
			{
				path: '/profile',
				meta: {
					title: '用户资料'
				},
				component: () => import('@/views/UserProfile.vue')
			},
			// 仪表盘
			dashboard,
			// 权限
			permissions,
			// 用户
			users,
			// 文件
			files,
			// 内容管理
			content,
			// 推荐位
			recommend,
			// 留言板
			message,
			// 设置
			setting
		]
	},
	
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = `${to.meta.title} - NSYS后台管理系统`;
    }
    next();
});

export default router
