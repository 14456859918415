
import { reactive, ref } from 'vue'
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        value: {
            type: String,
            default: ''
        },
        autofocus: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        cols: {
            type: Number,
            default: 8
        },
        rows: {
            type: Number,
            default: 4
        },
        maxlength: {
            type: Number,
            default: 140
        },
        placeholder: {
            type: String,
            default: '输入内容'
        }
    },
    watch:{
        value(nVal){
            if(nVal !== null)
                this.val = this.value;
        }
    },
    emits: ['update:value'],
    data(){
        return {
            val: ''
        }
    }
})

export default class NTextarea extends Vue{}
