<template>
    <n-modal class="n-messagebox" 
        v-model:show="isShow"
        height="auto" 
        @close="handlerClose"
    >
        <h2 class="messagebox-title">
            <span>{{ title }}</span>
            <i class="n-icon n-close-bold" @click="handlerClose"></i>
        </h2>
        <div class="messagebox-container">
            <i class="warning n-icon n-prompt-filling"></i>
            <div class="message">{{ message }}</div>
        </div>
        <div class="messagebox-footer">
            <n-button 
                v-if="showCancel"
                class="btn"
                :round="btnRound"
                :type="cancelType"
                @click="handlerCancel"
            >{{ cancelText }}</n-button>
            <n-button
                v-if="showConfirm"
                class="btn"
                :round="btnRound"
                :type="confirmType"
                @click="handlerConfirm"
            >{{ confirmText }}</n-button>
        </div>
    </n-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        show: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: '提示'
        },
        message: {
            type: String,
            default: '此操作将永久删除该文件, 是否继续?'
        },
        type: {
            type: String,
            default: 'warning'
        },
        showClose: {
            type: Boolean,
            default: true
        },
        showIcon: {
            type: Boolean,
            default: true
        },
        icon: {
            type: String,
            default: ''
        },
        btnSize: {
            type: String,
            default: ''
        },
        btnRound: {
            type: Boolean,
            default: true
        },
        showCancel: {
            type: Boolean,
            default: true
        },
        cancelText: {
            type: String,
            default: '取消'
        },
        cancelType: {
            type: String,
            default: ''
        },
        showConfirm: {
            type: Boolean,
            default: true
        },
        confirmText: {
            type: String,
            default: '确定'
        },
        confirmType: {
            type: String,
            default: 'primary'
        }
    },
    emits: ['update:show', 'confirm', 'cancel', 'close'],
    data(){
        return{
            isShow: false
        }
    },
    watch: {
        show(){
            this.isShow = this.show;
            if(!this.show) this.$emit('close');
        }
    },
    mounted(){
        this.isShow = this.show;
    },
    methods: {
        handlerClose(){
            this.$emit('update:show', false);
        },
        handlerCancel(){
            this.$emit('cancel');
        },
        handlerConfirm(){
            this.$emit('confirm');
        }
    }
})

export default class NMessagebox extends Vue {}
</script>

<style lang="scss" scoped>
.n-messagebox{
    @extend .flex-column;
    .messagebox-title{
        @extend .flex-row-between;
        margin: 0;
        padding: 15px;
        font-size: 16px;
        font-weight: normal;
        .n-icon{
            cursor: pointer;
            &:hover{
                color: $primary;
            }
        }
    }
    .messagebox-container{
        @extend .flex-row;
        padding: 0 15px;
        .n-icon{
            font-size: 20px;
            &.warning{
                color: $warning;
            }
        }
        .message{
            line-height: 20px;
            margin-left: 10px;
            margin-top: 2px;
            font-size: 13px;
        }
    }
    .messagebox-footer{
        @extend .flex-row;
        justify-content: flex-end;
        padding: 15px;
        .btn{
            margin-left: 10px;
        }
    }
}
</style>