import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import api from '@/api/api'
import utils from '@/utils/utils'

import NButton from './packages/button/src/Button.vue'
import NSubmenu from './packages/menu/src/SubMenu.vue'
import NAvatar from './packages/avatar/src/Avatar.vue'
import NCheckbox from './packages/checkbox/src/Checkbox.vue' 
import NCaptcha from './packages/captcha/src/Captcha.vue'
import NInput from './packages/input/src/Input.vue'
import NMessagebox from './packages/message-box/src/MessageBox.vue'
import NDropdown from './packages/dropdown/src/Dropdown.vue'
import NModal from './packages/modal/src/Modal.vue'
import NNotification from './packages/notification/src/Notification.vue'
import NPopconfirm from './packages/popconfirm/src/Popconfirm.vue'
import NScrollView from './packages/scroll-view/src/ScrollView.vue'
import NSelect from './packages/select/src/Select.vue'
import NSelectOption from './packages/select/src/SelectOption.vue'
import NSwitch from './packages/switch/src/Switch.vue'
import NTag from './packages/tag/src/Tag.vue'
import NUpload from './packages/upload/src/Upload.vue'
import NCascader from './packages/cascader/src/Cascader.vue'
import NDatePicker from './packages/datepicker/src/DatePicker.vue'
import NTimePicker from './packages/timepicker/src/TimePicker.vue'
import NTextarea from './packages/textarea/src/Textarea.vue'
import NTree from './packages/tree/src/Tree.vue'
import NTreeItem from './packages/tree/src/TreeItem.vue'

const app = createApp(App)
app.component('n-button', NButton)
app.component('n-submenu', NSubmenu)
app.component('n-avatar', NAvatar)
app.component('n-checkbox', NCheckbox)
app.component('n-captcha', NCaptcha)
app.component('n-input', NInput)
app.component('n-messagebox', NMessagebox)
app.component('n-dropdown', NDropdown)
app.component('n-modal', NModal)
app.component('n-notification', NNotification)
app.component('n-popconfirm', NPopconfirm)
app.component('n-scroll-view', NScrollView)
app.component('n-select', NSelect)
app.component('n-select-option', NSelectOption)
app.component('n-switch', NSwitch)
app.component('n-tag', NTag)
app.component('n-upload', NUpload)
app.component('n-cascader', NCascader)
app.component('n-date-picker', NDatePicker)
app.component('n-time-picker', NTimePicker)
app.component('n-textarea', NTextarea)
app.component('n-tree', NTree)
app.component('n-tree-item', NTreeItem)

app.config.globalProperties.$api = api
app.config.globalProperties.$utils = utils
app.use(store).use(router).mount('#app')
