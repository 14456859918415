<template>
    <div class="n-select-box">
        <div class="n-select-container">
            <div class="n-select-value" 
                :class="value === '' ? 'grey' : ''"
                @click="show = !show"
            >
                <span class="value">{{ value === '' ? placeholder : value }}</span>
                <down class="icon" 
                    :class="show ? 'show' : 'hide'"
                    theme="outline" 
                    size="14" fill="#999" 
                />
            </div>
            <div class="n-select-panel"
                :class="show ? 'show' : 'hide'"
                :style="`z-index:${zIndex};`"
                @click="show = false"
            >
                <template v-if="options.length > 0">
                    <n-select-option
                        v-for="(item, index) in refOptions" :key="index" 
                        :label="item.label"
                        :checked="item.label === value"
                        :index="index"
                        @choose="chooseItem"
                    />
                </template>
                <template v-else>
                    <slot></slot>
                </template>
            </div>
        </div>
        <div class="n-select-mask" v-if="show" @click="show = false"></div>
    </div>
</template>

<script lang="ts">
import { reactive, ref, toRefs } from 'vue';
import { Down } from '@icon-park/vue-next';
import NSelectOption from './SelectOption.vue';

export default {
    name: 'NSelect',
    components: {
        Down, NSelectOption
    },
    props: {
        width: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        },
        options: {
            type: Array,
            default: () => []
        },
        placeholder: {
            type: String, 
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        // 是否多选
        multiple: {
            type: Boolean,
            default: false
        },
        zIndex: {
            type: Number,
            default: 996
        }
    },
    setup(props: any, ctx: any){
        let show = ref(false),
            refOptions = reactive(props.options);

        function formatOptions(item: any, arr: any){
            arr.forEach((o: any) => {
                if(item == o) item.checked = true;
                else item.checked = false;
            })
        }
        function chooseItem(index: number){
            ctx.emit('choose', props.options[index]);
        }

        return {
            show,
            refOptions,
            chooseItem
        }
    }
}
</script>

<style lang="scss" scoped>
.n-select-box{
    position: relative;
    width: 100%;
    background-color: $white;
    .n-select-container{
        .n-select-value{
            @extend .flex-row-between;
            cursor: pointer;
            height: 33px;
            padding: 0 10px;
            border-radius: $radius;
            border: 1px solid $border-color;
            .value,.icon{
                @extend .flex-column-middle;
            }
            .icon{
                &.show{
                    transform: rotate(-180deg);
                }
                &.hide{
                    transform: rotate(0);
                }
            }
            &.grey{
                color: $info;
            }
            &:hover{
                box-shadow: 0 0 5px rgba(0,0,0,0.07);
            }
        }
        .n-select-panel{
            position: absolute;
            width: 100%;
            border-radius: $radius;
            background-color: $white;
            box-shadow: $shadow;
            transform-origin: 0 0;
            &.show{
                transform: scale3d(1,1,1);
            }
            &.hide{
                transform: scale3d(1,0,0);
            }
        }
    }
    .n-select-mask{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
</style>