export default {
    path: '/permissions',
    redirect: '/permissions/permissions',
    meta: {
        title: '权限 && 角色'
    },
    component: () => import('@/views/permissions/Layout.vue'),
    children: [
        {
            path: 'permissions',
            name: 'Permissions',
            meta: {
                title: '权限管理'
            },
            component: () => import('@/views/permissions/Permissions.vue'),
        }, 
        {
            path: 'permissions/create',
            meta: {
                title: '新建权限'
            },
            component: () => import('@/views/permissions/PermissionCreate.vue')
        }, {
            path: 'permissions/edit/:id',
            meta: {
                title: '编辑权限'
            },
            component: () => import('@/views/permissions/PermissionEdit.vue')
        },
        {
            path: 'roles',
            name: 'Roles',
            meta: {
                title: '角色管理'
            },
            component: () => import('@/views/permissions/Roles.vue')
        },
        {
            path: 'roles/create',
            meta: {
                title: '新建角色'
            },
            component: () => import('@/views/permissions/RoleCreate.vue')
        }, {
            path: 'roles/edit/:id',
            meta: {
                title: '编辑角色'
            },
            component: () => import('@/views/permissions/RoleEdit.vue')
        }
    ]
}