
import { Options, Vue } from 'vue-class-component';
import { Down } from '@icon-park/vue-next';

@Options({
    components: {
        Down
    },
    props: {
        menu: {
            type: Object,
            default: () => []
        }
    },
    data(){
        return {
            show: false,
            childMenuHeight: 0
        }
    },
    watch: {
        show(){
            if(this.menu.children && this.menu.children.length > 0)
                this.childMenuHeight = this.$refs['childMenu'].offsetHeight;
        }
    },
    methods: {
        hanlderClick(item: any){
            console.log(item)
            if(item){
                this.$router.push({ path: item.path })
            }
        }
    }
})

export default class NSubmenu extends Vue {}
