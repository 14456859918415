export default {
    path: '/messages',
    redirect: '/messages/list',
    meta: {
        title: '留言板'
    },
    component: () => import('@/views/message/Messages.vue'),
    children: [
        {
            path: '/messages/list',
            meta: {
                title: '留言板'
            },
            component: () => import('@/views/message/List.vue')
        }
    ]
}