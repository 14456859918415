
import { Options, Vue } from 'vue-class-component';
import NButton from '../../button/src/Button.vue';

@Options({
    name: 'NPopconfirm',
    components: {
        NButton
    },
    props: {
        content: {
            type: String,
            default: '确定？'
        },
        cancelShow: {
            type: Boolean,
            default: true
        },
        cancelText: {
            type: String,
            default: '取消'
        },
        confirmShow: {
            type: Boolean,
            default: true
        },
        confirmText: {
            type: String,
            default: '确定'
        },
        icon: {
            type: String,
            default: 'n-help'
        },
        iconType: {
            type: String,
            default: 'warning'
        },
        iconSize: {
            type: Number,
            default: 18
        },
        position: {
            type: String,
            default: 'bottom left'
        },
        zIndex: {
            type: [Number, String],
            default: 999
        }
    },
    emits: [
        'cancel',
        'confirm'
    ],
    data(){
        return{
            visible: false
        }
    },
    methods: {
        handlerConfirm(){
            this.visible = false;
            this.$emit('confirm');
        },
        handlerCancel(){
            this.visible = false;
            this.$emit('cancel');
        }
    },

})

export default class NPopconfirm extends Vue {}
