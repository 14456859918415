<template>
    <div class="n-input-container">
        <div class="n-input">
            <i 
                class="n-icon prefix" 
                :class="prefix" 
                v-if="prefix !== ''"
            ></i>
            <label class="input-label">
                <input 
                    class="input" 
                    :class="round ? 'round' : ''"
                    v-model="inputVal"
                    :disabled="disabled"
                    :readonly="readOnly"
                    :type="inputType"
                    :placeholder="placeholder"
                    :maxlength="maxlength !== 0 ? maxlength : ''"
                    :max="max !== 0 ? max : ''"
                    :min="min !== 0 ? min : ''"
                    :style="`
                        padding-left: ${prefix !== '' ? 30 : 10}px;
                        padding-right: ${suffix !== '' || clear || showPassword ? 30 : 10}px;
                    `"
                    @input="$emit('update:value', inputVal)"
                    @change="$emit('change', inputVal)"
                    @focus="$emit('focus'); handlerSelect($event)"
                    @blur="$emit('blur')"
                    @onSelect="$emit('select')"
                    @keypress="handlerKeypress($event)"
                />
            </label>

            <template v-if="inputVal !== '' && suffix === ''">
                <i 
                    class="n-icon n-close-bold pointer suffix" 
                    v-if="clear && !showPassword"
                    @click="handlerClear"
                ></i>
                <i 
                    class="n-icon pointer suffix"
                    :class="inputType === 'password' ? 'n-hide' : 'n-browse'"
                    v-if="!clear && showPassword"
                    @click="handlerShowPassword"
                ></i>
            </template>
            <i class="n-icon suffix" :class="suffix" v-if="suffix !== ''"></i>
        </div>
        <p :class="[msgType, 'input-msg-box']" v-if="msg !== ''">{{ msg }}</p>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        value: {
            type: [String, Number],
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        placeholder: {
            type: String, 
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        maxlength: {
            type: Number,
            default: 0
        },
        max: {
            type: [Number, Date],
            default: 0
        },
        min: {
            type: [Number, Date],
            default: 0
        },
        autoSelect: {
            type: Boolean,
            default: true
        },
        prefix: {
            type: String,
            default: ''
        },
        suffix: {
            type: String,
            default: ''
        },
        clear: {
            type: Boolean,
            default: false
        },
        showPassword: {
            type: Boolean,
            default: false
        },
        round: {
            type: Boolean,
            default: false
        },
        msg: {
            type: String,
            default: ''
        },
        msgType: {
            type: String,
            default: 'error'
        }
    },
    emits: [ 'update:value', 'update:msg', 'change', 'focus', 'blur', 'keypress', 'enter' ],
    data(){
        return{
            inputVal: '',
            inputType: ''
        }
    },
    watch:{
        value(){
            this.inputVal = this.value;
        },
        type(){
            this.inputType = this.type;
        }
    },
    mounted(){
        this.inputVal = this.value;
        this.inputType = this.type;
    },
    methods: {
        handlerClear(){
            this.inputVal = '';
            this.$emit('update:value', '');
            this.$emit('update:msg', '');
        },
        handlerShowPassword(){
            this.inputType = this.inputType === 'text' ? 'password' : 'text';
        },
        handlerSelect(e: any){
            if(this.autoSelect) e.currentTarget.select();
        },
        handlerKeypress(e: any){
            this.$emit('keypress', e);
            if(e.code === 'Enter') this.$emit('enter', e);
        }
    }
})

export default class NInput extends Vue {}
</script>

<style lang="scss" scoped>
.n-input-container{
    @extend .flex-column;
    width: 100%;
    .input-msg-box{
        margin: -10px 0 0;
        padding: 15px 10px 5px;
        border-radius: 0 0 $radius-big $radius-big;
        &.error{
            background-color: $danger-light;
            color: $danger;
        }
        &.success{
            background-color: $success-light;
            color: $success;
        }
        &.danger{
            background-color: $danger-light;
            color: $danger;
        }
    }
}
.n-input{
    @extend .flex-row;
    position: relative;
    width: 100%;
    height: $default-size;
    .input-label{
        width: 100%;
    }
    .input{
        @extend .flex-column-middle;
        z-index: 1;
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: $radius;
        border: 1px solid $border-color;
        background-color: $white;
        &:focus{
            border-color: $border-color-dark;
            box-shadow: 1px 1px 5px rgba(0,0,0,.07);
        }
        &.round{
            border-radius: $radius-round;
        }
        &[read-only]{
            cursor: not-allowed;
        }
    }
    .n-icon{
        @extend .flex-column-center;
        z-index: 2;
        position: absolute;
        width: 30px;
        height: 100%;
        top: 0;
        font-size: 16px;
        color: $info;
        &.prefix{
            left: 0;
        }
        &.suffix{
            right: 0;
        }
        &.pointer{
            cursor: pointer;
            &:hover{
                color: $text-color;
            }
        }
    }

    
}
</style>