export default{
    path: '/files',
    redirect: '/files/all',
    meta: {
        title: '文件管理'
    },
    component: () => import('@/views/files/Layout.vue'),
    children: [
        {
            path: 'all',
            name: 'All Files',
            meta: {
                title: '全部'
            },
            component: () => import('@/views/files/All.vue')
        },{
            path: 'documents',
            name: 'Documents',
            meta: {
                title: '文档'
            },
            component: () => import('@/views/files/Documents.vue')
        },{
            path: 'pictures',
            name: 'Pictures',
            meta: {
                title: '图片'
            },
            component: () => import('@/views/files/Pictures.vue')
        },{
            path: 'videos',
            name: 'Videos',
            meta: {
                title: '视频'
            },
            component: () => import('@/views/files/Videos.vue')
        },{
            path: 'audios',
            name: 'Audios',
            meta: {
                title: '音频'
            },
            component: () => import('@/views/files/Audios.vue')
        },{
            path: 'others',
            name: 'Others',
            meta: {
                title: '其他'
            },
            component: () => import('@/views/files/Others.vue')
        }
    ]
}