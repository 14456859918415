<template>
    <div 
        v-if="show"
        class="n-tag-box" 
        :class="[size, theme, type]"
        @click="handlerClick"
    >
        <span class="tag-container">
            <slot></slot>
        </span>
        <i v-if="closeable" class="n-icon n-close-bold" @click="handlerClose"></i>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        type: {
            type: String,
            default: ''
        },
        closeable: {
            type: Boolean,
            default: true
        },
        size: {
            type: String,
            default: ''
        },
        // 可选plain镂空，default浅色背景，fill实心填充
        theme: {
            type: String,
            default: 'plain'
        }
    },
    emits: ['click', 'close'],
    data(){
        return{
            show: true
        }
    },
    methods: {
        handlerClick(){
            this.$emit('click');
        },
        handlerClose(){
            this.$emit('close');
            this.show = false;
        }
    }
})

export default class NTag extends Vue {}
</script>

<style lang="scss" scoped>
.n-tag-box{
    @extend .flex-row-between;
    height: $default-size;
    padding: 0 8px;
    border-radius: $radius;
    border: 1px solid $border-color;
    .tag-container,
    .n-icon{
        @extend .flex-column-middle;
    }
    .n-icon{
        cursor: pointer;
        margin-left: 5px;
        &::before{
            width: 14px;
            height: 14px;
            border-radius: $radius-circle;
            background-color: $white;
            font-size: 12px;
            color: $info;
        }
        &:hover::before{
            color: $white;
            background-color: $info;
        }
    }

    // size
    &.small{
        height: 22px;
        padding: 0 3px;
        .n-icon{
            margin-left: 3px;
        }
    }
    &.medium{
        height: 28px;
        padding: 0 5px;
        .n-icon{
            margin-top: 1px;
            margin-left: 3px;
        }
    }

    // theme
    &.plain{
        background: none;

        // type
        &.primary{
            border-color: $primary;
            color: $primary;
            .n-icon{
                &::before{
                    color: $primary;
                }
                &:hover::before{
                    color: $white;
                    background-color: $primary;
                }
            }
        }
        &.success{
            border-color: $success;
            color: $success;
            .n-icon{
                &::before{
                    color: $success;
                }
                &:hover::before{
                    color: $white;
                    background-color: $success;
                }
            }
        }
        &.warning{
            border-color: $warning;
            color: $warning;
            .n-icon{
                &::before{
                    color: $warning;
                }
                &:hover::before{
                    color: $white;
                    background-color: $warning;
                }
            }
        }
        &.danger{
            border-color: $danger;
            color: $danger;
            .n-icon{
                &::before{
                    color: $danger;
                }
                &:hover::before{
                    color: $white;
                    background-color: $danger;
                }
            }
        }
    }
    &.default{
        background-color: $light;

        // type
        &.primary{
            border-color: $primary;
            background-color: $primary-light;
            color: $primary;
            .n-icon{
                &::before{
                    color: $primary;
                    background: none;
                }
                &:hover::before{
                    color: $white;
                    background-color: $primary;
                }
            }
        }
        &.success{
            border-color: $success;
            background-color: $success-light;
            color: $success;
            .n-icon{
                &::before{
                    color: $success;
                    background: none;
                }
                &:hover::before{
                    color: $white;
                    background-color: $success;
                }
            }
        }
        &.warning{
            border-color: $warning;
            background-color: $warning-light;
            color: $warning;
            .n-icon{
                &::before{
                    color: $warning;
                    background: none;
                }
                &:hover::before{
                    color: $white;
                    background-color: $warning;
                }
            }
        }
        &.danger{
            border-color: $danger;
            background-color: $danger-light;
            color: $danger;
            .n-icon{
                &::before{
                    color: $danger;
                    background: none;
                }
                &:hover::before{
                    color: $white;
                    background-color: $danger;
                }
            }
        }
    }
    &.fill{
        background-color: $info;
        border-color: $info;
        color: $white;
        .n-icon{
            &::before{
                background: none;
                color: $white;
            }
            &:hover::before{
                background-color: $white;
                color: $info;
            }
        }

        // type
        &.primary{
            border-color: $primary;
            background-color: $primary;
            color: $white;
            .n-icon{
                &::before{
                    color: $white;
                    background: none;
                }
                &:hover::before{
                    color: $primary;
                    background-color: $white;
                }
            }
        }
        &.success{
            border-color: $success;
            background-color: $success;
            color: $white;
            .n-icon{
                &::before{
                    color: $white;
                    background: none;
                }
                &:hover::before{
                    color: $success;
                    background-color: $white;
                }
            }
        }
        &.warning{
            border-color: $warning;
            background-color: $warning;
            color: $white;
            .n-icon{
                &::before{
                    color: $white;
                    background: none;
                }
                &:hover::before{
                    color: $warning;
                    background-color: $white;
                }
            }
        }
        &.danger{
            border-color: $danger;
            background-color: $danger;
            color: $white;
            .n-icon{
                &::before{
                    color: $white;
                    background: none;
                }
                &:hover::before{
                    color: $danger;
                    background-color: $white;
                }
            }
        }
    }
}
</style>