<template>
    <div class="n-scroll-view"
        :class="[ 
            type, size, 
            scrollX ? 'scroll-x' : '', 
            scrollY ? 'scroll-y' : '',
            hiddenBar ? 'hidden' : ''
        ]"
        :style="`
            width:${width};
            height:${height}; 
        `"
        ref="scrollView"
        @scroll="handlerScroll"
    >
        <div class="scroll-view-container">
            <slot></slot>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        width: {
            type: String,
            default: ''
        },
        height: {
            type: String,
            default: ''
        },
        scrollX: {
            type: Boolean,
            default: false
        },
        scrollY: {
            type: Boolean,
            default: true
        },
        type: {
            type: String,
            defualt: 'dark'
        },
        size: {
            type: String,
            default: 'medium'
        },
        hiddenBar: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        handlerScroll(){
            // 
        }
    }
})

export default class NScrollView extends Vue {}
</script>

<style lang="scss" scoped>
.n-scroll-view{

    .scroll-view-container{
        z-index: 99;
        position: relative;
        width: 100%;
    }

    &.hidden::-webkit-scrollbar-thumb{
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        position: absolute;
        border-radius: 10px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #535353;
    }
    &::-webkit-scrollbar-track {
        display: none;
        // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        // background: #EDEDED;
    }

    &.scroll-y{
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar {
            height: 1px;
        }
        &.small::-webkit-scrollbar {
            width: 4px;
        }
        &.medium::-webkit-scrollbar {
            width: 10px;
        }
        &.large::-webkit-scrollbar {
            width: 15px;
        }
    }
    &.scroll-x{
        overflow-x: auto;
        overflow-y: hidden;
        &::-webkit-scrollbar {
            width: 1px;
        }
        &.small::-webkit-scrollbar {
            height: 6px;
        }
        &.medium::-webkit-scrollbar {
            height: 10px;
        }
        &.large::-webkit-scrollbar {
            height: 15px;
        }
    }

    // type
    &.dark{
        &::-webkit-scrollbar-thumb {
            background: #535353;
        }
        &::-webkit-scrollbar-track {
            background: rgba(0,0,0,.1);
        }
    }
    &.primary{
        &::-webkit-scrollbar-thumb {
            background: $primary;
        }
        &::-webkit-scrollbar-track {
            background: none;
        }
    }
    &.warning{
        &::-webkit-scrollbar-thumb {
            background: $warning;
        }
        &::-webkit-scrollbar-track {
            background: $warning-light;
        }
    }
    &.danger{
        &::-webkit-scrollbar-thumb {
            background: $danger;
        }
        &::-webkit-scrollbar-track {
            background: $danger-light;
        }
    }
    &.success{
        &::-webkit-scrollbar-thumb {
            background: $success;
        }
        &::-webkit-scrollbar-track {
            background: $success-light;
        }
    }

}
</style>