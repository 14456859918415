
import { Options, Vue } from 'vue-class-component';
import { RightOne } from '@icon-park/vue-next';

@Options({
    components: {
        RightOne
    },
    props: {
        data: {
            type: [ Object, Array ],
            require: true
        }
    },
    data(){
        return {
            isOpen: false,
            opened: false,
            checked: false
        }
    },
    computed: {
        hasChildren(){
            return this.data.children ? true : false;
        }
    },
    emits: ['check', 'child-check'],
    methods: {
        toggle(){
            this.checked = !this.checked;
            this.$emit('check', this.data);
            if(this.hasChildren) {
                if(this.isOpen){
                    this.opened = false;
                    setTimeout(() => {
                        this.isOpen = false;
                    }, 220);
                } else {
                    this.isOpen = true;
                    setTimeout(() => {
                        this.opened = true;
                    },1);
                }
            }
        },
        childClick(item: any){
            this.$emit('child-check', item);
        }
    }
})

export default class NTreeItem extends Vue {}
