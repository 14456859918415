
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        show: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: '提示'
        },
        message: {
            type: String,
            default: '此操作将永久删除该文件, 是否继续?'
        },
        type: {
            type: String,
            default: 'warning'
        },
        showClose: {
            type: Boolean,
            default: true
        },
        showIcon: {
            type: Boolean,
            default: true
        },
        icon: {
            type: String,
            default: ''
        },
        btnSize: {
            type: String,
            default: ''
        },
        btnRound: {
            type: Boolean,
            default: true
        },
        showCancel: {
            type: Boolean,
            default: true
        },
        cancelText: {
            type: String,
            default: '取消'
        },
        cancelType: {
            type: String,
            default: ''
        },
        showConfirm: {
            type: Boolean,
            default: true
        },
        confirmText: {
            type: String,
            default: '确定'
        },
        confirmType: {
            type: String,
            default: 'primary'
        }
    },
    emits: ['update:show', 'confirm', 'cancel', 'close'],
    data(){
        return{
            isShow: false
        }
    },
    watch: {
        show(){
            this.isShow = this.show;
            if(!this.show) this.$emit('close');
        }
    },
    mounted(){
        this.isShow = this.show;
    },
    methods: {
        handlerClose(){
            this.$emit('update:show', false);
        },
        handlerCancel(){
            this.$emit('cancel');
        },
        handlerConfirm(){
            this.$emit('confirm');
        }
    }
})

export default class NMessagebox extends Vue {}
