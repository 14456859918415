<template>
    <div 
        class="n-modal" 
        v-if="show" 
        :style="`z-index:${zIndex};`"
        @click="$emit('close'); $emit('update:show', false)"
    >
        <div class="mask" 
            :class="isShow ? 'show' : 'hide'" 
            :style="`z-index:${Number(zIndex)-1}`"
        ></div>
        <div 
            class="modal-container" 
            :class="isShow ? 'show' : 'hide'"
            ref="modal"
            :style="`
                width:${width}px;
                height:${typeof height === 'number' ? height+'px' : height};
                top:${typeof height === 'number' ? '50%' : top};
                left:${left};
                margin-top:-${typeof height === 'number' ? height/2+100 : 0}px;
                margin-left:-${width/2}px;
                z-index:${zIndex};
            `"
            @click.stop
        >
            <slot></slot>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        show: {
            type: Boolean,
            default: false
        },
        width: {
            type: Number,
            default: 360
        },
        height: {
            type: [Number, String],
            default: 220
        },
        top: {
            type: String,
            default: '50%'
        },
        left: {
            type: String,
            default: '50%'
        },
        zIndex: {
            type: [Number, String],
            default: 999
        }
    },
    data(){
        return{
            modalClient: {
                width: '',
                height: ''
            },
            isShow: false
        }
    },
    emits: ['update:show', 'close'],
    watch: {
        show(){
            setTimeout(() => {
                this.isShow = this.show;
            },100);
        }
    },
    mounted(){
        this.isShow = this.show;
    }
})

export default class NModal extends Vue {}
</script>

<style lang="scss" scoped>
.n-modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: left;
    .mask{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $mask;
        &.show{
            opacity: 1;
        }
        &.hide{
            opacity: 0;
        }
    }
    .modal-container{
        @extend .flex-column;
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: $radius-big;
        background-color: $white;
        box-shadow: $shadow;
        &.hide{
            transform: scale3d(0,0,0);
        }
        &.show{
            transform: scale3d(1,1,1);
        }
    }
}
</style>