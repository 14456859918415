<template>
    <div class="n-cascader" :style="`z-index:${Number(zIndex)}`">
        <div class="cascader-container" @click="show = !show">
            <span class="grey" v-if="current.parent === '' && value === ''">{{ placeholder }}</span>
            <div class="text-div">
                <template v-if="current.parent !== ''">
                    <span class="text">{{ options[current.parent].label }}</span>
                    <template v-if="current.child !== ''">
                        <em>/</em>
                        <span class="text">{{ child.children[current.child].label }}</span>
                    </template>
                    <template v-if="current.grandsun !== ''">
                        <em>/</em>
                        <span class="text">{{ sun.children[sunCurrent].label }}</span>
                    </template>
                </template>
                <template v-if="value !== '' && current.parent === ''">
                    <span>{{ value }}</span>
                </template>
            </div>
            <down class="arrow" :class="show ? 'show' : ''"  theme="outline" size="14" fill="#999"/>
        </div>
        <div class="cascader-options" :class="show ? 'show' : 'hide'" @click.stop>
            <div class="arrow"></div>
            <template v-if="options.length === 0">
                <div class="options-item">
                    <div class="one-item" @click="show = false">
                        <span class="text">暂无选项</span>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="options-item">
                    <template v-for="(item, index) in options" :key="index">
                        <div class="one-item" 
                            :class="current.parent === index ? 'checked' : ''"
                            @click="handlerClick(item, index)"
                        >
                            <span class="text">{{ item.label }}</span>
                            <right v-if="item.children && item.children.length" theme="outline" size="14" fill="#999"/>
                        </div>
                    </template>
                </div>
                <div class="options-item" v-if="child.children && child.children.length > 0">
                    <template v-for="(child, childIndex) in child.children" :key="childIndex">
                        <div class="one-item" 
                            :class="current.child === childIndex ? 'checked' : ''"
                            @click="handlerClickChild(child, childIndex)"
                        >
                            <span class="text">{{ child.label }}</span>
                            <right v-if="child.children && child.children.length" theme="outline" size="14" fill="#999"/>
                        </div>
                    </template>
                </div>
                <div class="options-item" v-if="sun.children && sun.children.length > 0">
                    <div class="one-item" 
                        v-for="(sun, sunIndex) in sun.children" :key="sunIndex"
                        :class="current.grandsun === sunIndex ? 'checked' : ''"
                        @click="current.grandsun = sunIndex; show = false"
                    >
                        <span class="text">{{ sun.label }}</span>
                    </div>
                </div>
            </template>
        </div>
    </div>
    <div v-if="show" class="n-cascader-mask" :style="`z-index:${Number(zIndex)-1}`" @click="show = false"></div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Down, Right } from '@icon-park/vue-next';

@Options({
    components: {
        Down, Right
    },
    props: {
        value: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        options: {
            type: [Array, Object],
            default: () => []
        },
        // 是否多选
        multiple: {
            type: Boolean,
            default: false
        },
        zIndex: {
            type: [Number, String],
            default: 996
        }
    },
    data(){
        return{
            show: false,
            current: {
                parent: '',
                child: '',
                grandson: ''
            },
            childCurrent: '',
            sunCurrent: '',
            child: {},
            sun: {},
        }
    },
    watch: {
        'current': {
            handler(nVal){
                this.$emit('change', nVal);
                this.show = false;
            },
            deep: true
        }
    },
    emits: ['change'],
    methods: {
        handlerClick(item: { children: [] }, index: number){
            this.current.parent = index;
            this.current = {
                parent: index,
                child: '',
                grandsun: ''
            }
            this.sun = {};
            this.child = item.children;
            if(item.children && item.children.length){
                this.child = item
            }
            if(!this.multiple) this.show = false;
        },
        handlerClickChild(item: { children: [] }, index: number){
            this.current.child = index;
            this.current.grandsun = '';
            if(item.children && item.children.length){
                this.sun = item
            }
            if(!this.multiple) this.show = false;
        },
        // 返回数组层级
        countColumn(options: any[], columns = 0) {
            if (options.length) {
                columns++
            }
            const current = options.filter(o => o.children && o.children.length)
            if (current.length) {
                columns = this.countColumn(current[0].children, columns)
            }
            return columns
        },
    }
})

export default class NCascader extends Vue {}
</script>

<style lang="scss" scoped>
.n-cascader{
    position: relative;
    
    .cascader-container{
        @extend .flex-row-between;
        cursor: pointer;
        // width: 220px;
        height: $default-size;
        padding: 5px 10px;
        border-radius: $radius;
        border: 1px solid $border-color;
        background-color: $white;
        &:hover{
            border-color: $border-color-dark;
        }
        .grey,.i-icon,.text,em{
            @extend .flex-column-middle;
        }
        .grey{
            color: $grey;
        }
        .text-div{
            @extend .flex-row;
            em{
                margin: 3px 3px 0;
                color: $grey;
            }
            .text{
                white-space: nowrap;
            }
        }
        .arrow{
            transform: rotate(0);
            &.show{
                transform: rotate(180deg);
            }
        }
    }

    .cascader-options{
        @extend .flex-row;
        position: absolute;
        margin-top: 10px;
        border-radius: $radius;
        background-color: $white;
        box-shadow: $shadow;
        transform-origin: 0 0;
        .arrow{
            position: absolute;
            display: block;
            width: 10px;
            height: 10px;
            top: -4px;
            left: 15px;
            background: transparent;
            border-style: solid;
            border-width: 5px;
            transform: rotate(45deg);
            border-color: #fff transparent transparent #fff;
            box-shadow: -2px -2px 5px rgba(0,0,0,.06);
        }
        .no-options{
            color: $info;
        }
        .options-item{
            @extend .flex-column;
            width: 140px;
        }
        .one-item{
            @extend .flex-row-between;
            cursor: pointer;
            height: $default-size;
            padding: 10px;
            .text{
                @extend .flex-column-middle;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .n-icon{
                @extend .flex-column-middle;
                font-size: 14px;
                color: $success;
                opacity: 0;
            }
            &.checked{
                color: $primary;
                background-color: $primary-light;
                .n-icon{
                    opacity: 1;
                }
            }
            &:hover{
                background-color: $primary-light;
            }
        }
        &.show{
            transform: scale3d(1,1,1);
        }
        &.hide{
            transform: scale3d(1,0,0);
        }
    }
    &.show{
        transform: scale3d(1,1,1);
    }
    &.hide{
        transform: scale3d(1,0,0);
    }
}
.n-cascader-mask{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: none;
}
</style>