<template>
    <div class="n-select-option"
        :class="checked ? 'checked' : ''"
        @click="handlerClick"
    >
        <span class="label">{{ label }}</span>
        <check-small class="icon" theme="filled" size="14" fill="#00cc00" :strokeWidth="3"/>
    </div>
</template>

<script lang="ts">
import { reactive, ref, toRefs } from 'vue';
import { CheckSmall } from '@icon-park/vue-next';

export default {
    name: 'NSelectOption',
    components: {
        CheckSmall
    },
    props: {
        label: {
            type: String,
            default: ''
        },
        index: {
            type: Number,
            default: 0
        },
        checked: {
            type: Boolean,
            default: false
        }
    },
    setup(props: any, ctx: any){
        function handlerClick(){
            ctx.emit('choose', props.index)
        }

        return {
            handlerClick
        }
    }
}
</script>

<style lang="scss" scoped>
.n-select-option{
    @extend .flex-row-between;
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    padding: 0 6px;
    .icon{
        opacity: 0;
    }
    &.checked{
        background-color: $primary-light;
        color: $primary;
        .icon{
            opacity: 1;
        }
    }
    &:hover{
        background-color: $primary-light;
        color: $primary;
    }
}
</style>