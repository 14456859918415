
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        trigger: {
            type: String,
            default: 'hover'
        },
        width: {
            type: String,
            default: '100%'
        },
        position: {
            type: String,
            default: 'left bottom'
        }
    },
    data(){
        return {
            show: false,
            overMenu: false,
            dropdownClient: {
                width: '',
                height: ''
            }
        }
    },
    mounted(){
        this.dropdownClient.width = this.$refs['dropdownLink'].offsetWidth
        this.dropdownClient.height = this.$refs['dropdownLink'].offsetHeight
    },
    methods: {
        overShow(){
            if(this.trigger === 'hover') {
                this.show = true;
            }
        },
        clickShow(){
            if(this.trigger === 'click') this.show = !this.show;
        },
        outHide(){
            if(this.trigger === 'hover') {
                setTimeout(() => {
                    this.show = false;
                }, 300);
            }
        }
    }
})

export default class NDropdown extends Vue {}
