
import { Options, Vue } from 'vue-class-component';
import { Down, Right } from '@icon-park/vue-next';

@Options({
    components: {
        Down, Right
    },
    props: {
        value: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        options: {
            type: [Array, Object],
            default: () => []
        },
        // 是否多选
        multiple: {
            type: Boolean,
            default: false
        },
        zIndex: {
            type: [Number, String],
            default: 996
        }
    },
    data(){
        return{
            show: false,
            current: {
                parent: '',
                child: '',
                grandson: ''
            },
            childCurrent: '',
            sunCurrent: '',
            child: {},
            sun: {},
        }
    },
    watch: {
        'current': {
            handler(nVal){
                this.$emit('change', nVal);
                this.show = false;
            },
            deep: true
        }
    },
    emits: ['change'],
    methods: {
        handlerClick(item: { children: [] }, index: number){
            this.current.parent = index;
            this.current = {
                parent: index,
                child: '',
                grandsun: ''
            }
            this.sun = {};
            this.child = item.children;
            if(item.children && item.children.length){
                this.child = item
            }
            if(!this.multiple) this.show = false;
        },
        handlerClickChild(item: { children: [] }, index: number){
            this.current.child = index;
            this.current.grandsun = '';
            if(item.children && item.children.length){
                this.sun = item
            }
            if(!this.multiple) this.show = false;
        },
        // 返回数组层级
        countColumn(options: any[], columns = 0) {
            if (options.length) {
                columns++
            }
            const current = options.filter(o => o.children && o.children.length)
            if (current.length) {
                columns = this.countColumn(current[0].children, columns)
            }
            return columns
        },
    }
})

export default class NCascader extends Vue {}
