
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        value: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'primary'
        },
        size: {
            type: String,
            default: 'large'
        },
        activeText: {
            type: String,
            default: ''
        },
        activeColor: {
            type: String,
            default: ''
        },
        inactiveText: {
            type: String,
            default: ''
        },
        inactiveColor: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:value'],
    methods: {
        handlerClick(){
            if(!this.disabled){
                this.$emit('update:value', !this.value)
            }
        }
    }
})

export default class NSwitch extends Vue {}
