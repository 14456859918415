
import { reactive, ref, toRefs } from 'vue';
import { CheckSmall } from '@icon-park/vue-next';

export default {
    name: 'NSelectOption',
    components: {
        CheckSmall
    },
    props: {
        label: {
            type: String,
            default: ''
        },
        index: {
            type: Number,
            default: 0
        },
        checked: {
            type: Boolean,
            default: false
        }
    },
    setup(props: any, ctx: any){
        function handlerClick(){
            ctx.emit('choose', props.index)
        }

        return {
            handlerClick
        }
    }
}
