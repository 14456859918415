
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        time: {
            type: Object,
            default: {
                hour: '',
                minute: '',
                second: ''
            }
        },
        position: {
            type: String,
            default: 'bottom left'
        },
        zIndex: {
            type: Number,
            default: 996
        }
    },
    data(){
        return{
            state: {
                hour: [],
                minute: [],
                second: []
            },
            current: {
                hour: '00',
                minute: '00',
                second: '00'
            },
            show: false
        }
    },
    emits: ['change', 'update:time'],
    watch: {
        'current':{
            handler(nVal){
                this.$emit('change', nVal);
                this.$emit('update:time', nVal);
            },
            deep: true
        },
        'time': {
            handler(){
                if(this.time) this.current = this.time;
                else {
                    let date = new Date();
                    this.current = {
                        hour: date.getHours(),
                        minute: date.getMinutes(),
                        second: date.getSeconds()
                    }
                }
            },
            deep: true
        }
    },
    mounted(){
        for(let i=0; i<24; i++){
            if(i<10) this.state.hour.push('0'+i);
            else this.state.hour.push(i);
        }
        for(let i=0; i<60; i++){
            if(i<10) {
                this.state.minute.push('0'+i);
                this.state.second.push('0'+i);
            } else {
                this.state.minute.push(i);
                this.state.second.push(i);
            }
        }

        let date = new Date();
        this.current = {
            hour: date.getHours() < 10 ? `0${date.getHours()}` : date.getHours(),
            minute: date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes(),
            second: date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()
        }
    }
})

export default class NTimePicker extends Vue {}
