
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        width: {
            type: String,
            default: ''
        },
        height: {
            type: String,
            default: ''
        },
        scrollX: {
            type: Boolean,
            default: false
        },
        scrollY: {
            type: Boolean,
            default: true
        },
        type: {
            type: String,
            defualt: 'dark'
        },
        size: {
            type: String,
            default: 'medium'
        },
        hiddenBar: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        handlerScroll(){
            // 
        }
    }
})

export default class NScrollView extends Vue {}
