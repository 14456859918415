<template>
    <div class="n-tree">
        <ul class="n-tree-container" v-for="(item, index) in tree" :key="index">
            <n-tree-item :data="item" @check="handlerCheck" @childCheck="handlerChild" />
        </ul>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { RightOne } from '@icon-park/vue-next';
import NTreeItem from './TreeItem.vue';

@Options({
    components: {
        RightOne, NTreeItem
    },
    props: {
        options: {
            type: Array,
            default: () => []
        }
    },
    data(){
        return {
            tree: []
        }
    },
    mounted(){
        this.tree = this.options;
    },
    methods: {
        handlerCheck(item: any){
            console.log(item)
        },
        handlerChild(item: any){
            console.log(item)
        }
    }
})

export default class NTree extends Vue {}
</script>

<style lang="scss" scoped>
.n-tree{
    @extend .flex-column;
    background-color: $white;
    .item{
        @extend .flex-column;
        padding: 5px;
        &-container{
            @extend .flex-row;
        }
    }
}
</style>