<template>
    <div class="n-avatar"
        :class="shape"
        :style="`width:${size}px; height:${size}px`"
        @click="previewShow = true"
    >
        <img class="avatar-img" v-if="src && src.length > 0" :src="src" />
        <span v-else 
            class="avatar-text" 
            :style="`font-size:${size/2}px`"
        >{{ name.substring(0,1) }}</span>   

        <div class="avatar-preview" 
            v-if="src && src.length > 0 && previewShow" @click.stop="previewShow = false"
        >
            <img :style="`margin:-${imgClient.height/2}px 0 0 -${imgClient.width/2}px`" :src="src" />
            <close class="close-btn" theme="outline" size="24" fill="#fff" />
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Close } from '@icon-park/vue-next';

@Options({
    components: {
        Close
    },
    props: {
        src: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: 'Avatar'
        },
        size: {
            type: Number,
            default: 30
        },
        shape: {
            type: String,
            default: 'circle'
        }
    },
    data(){
        return {
            previewShow: false,
            imgClient: {
                width: 0,
                height: 0
            }
        }
    },
    mounted(){
        let img = new Image(), that = this;
        img.src = this.src;
        img.onload = () => {
            that.imgClient.width = img.width;
            that.imgClient.height = img.height;
        }
    },
})

export default class NAvatar extends Vue {}
</script>

<style lang="scss" scoped>
.n-avatar{
    @extend .flex-column-center;
    cursor: pointer;
    background-color: #f1f1f1;
    color: $info;
    overflow: hidden;
    &.circle{
        border-radius: $radius-circle;
    }
    .avatar-img{
        width: 100%;
    }
    .avatar-text{
        text-transform: capitalize;
    }
    .avatar-preview{
        z-index: 9999;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $mask;
        img{
            position: absolute;
            top: 40%;
            left: 50%;
        }
        .close-btn{
            position: absolute;
            right: 0;
            top: 0;
            padding: 15px;
            background-color: $mask;
        }
    }
}
</style>