<template>
    <div class="n-popconfirm" ref="refConfirm">
        <div class="slot-container" @click="visible = !visible">
            <slot></slot>
        </div>
        <div class="popconfirm-container" 
            :class="[
                visible ? 'show' : 'hide',
                position
            ]" 
            :style="`z-index:${zIndex}`"
        >
            <div class="arrow"></div>
            <div class="bd">
                <i class="n-icon" 
                    :class="[icon, iconType]"
                    :style="`font-size:${iconSize}px`"
                ></i>
                <div>{{ content }}</div>
            </div>
            <div class="operation-btns">
                <n-button v-if="cancelShow"
                    class="operation-btn" size="small" 
                    round
                    @click="handlerCancel"
                >{{ cancelText }}</n-button>
                <n-button v-if="confirmShow"
                    class="operation-btn" type="primary" 
                    size="small" round
                    @click="handlerConfirm"
                >{{ confirmText }}</n-button>
            </div>
        </div>
        <div class="mask" v-if="visible" @click="visible = false"></div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import NButton from '../../button/src/Button.vue';

@Options({
    name: 'NPopconfirm',
    components: {
        NButton
    },
    props: {
        content: {
            type: String,
            default: '确定？'
        },
        cancelShow: {
            type: Boolean,
            default: true
        },
        cancelText: {
            type: String,
            default: '取消'
        },
        confirmShow: {
            type: Boolean,
            default: true
        },
        confirmText: {
            type: String,
            default: '确定'
        },
        icon: {
            type: String,
            default: 'n-help'
        },
        iconType: {
            type: String,
            default: 'warning'
        },
        iconSize: {
            type: Number,
            default: 18
        },
        position: {
            type: String,
            default: 'bottom left'
        },
        zIndex: {
            type: [Number, String],
            default: 999
        }
    },
    emits: [
        'cancel',
        'confirm'
    ],
    data(){
        return{
            visible: false
        }
    },
    methods: {
        handlerConfirm(){
            this.visible = false;
            this.$emit('confirm');
        },
        handlerCancel(){
            this.visible = false;
            this.$emit('cancel');
        }
    },

})

export default class NPopconfirm extends Vue {}
</script>

<style lang="scss" scoped>
.n-popconfirm{
    position: relative;
    text-align: left;
    
    .popconfirm-container{
        position: absolute;
        width: 220px;
        border-radius: $radius-big;
        background-color: $white;
        box-shadow: $shadow;
        transform-origin: 4px 0;
        .arrow{
            position: absolute;
            display: block;
            width: 10px;
            height: 10px;
            background: transparent;
            border-style: solid;
            border-width: 5px;
            transform: rotate(45deg);
            border-color: #fff transparent transparent #fff;
            box-shadow: -2px -2px 5px rgba(0,0,0,.06);
        }
        .bd{
            @extend .flex-row;
            padding: 10px;
            .n-icon{
                margin-right: 5px;
                &.warning{
                    color: $warning;
                }
                &.danger{
                    color: $danger;
                }
                &.info{
                    color: $info;
                }
            }
        }
        .operation-btns{
            @extend .flex-row;
            justify-content: flex-end;
            padding: 0 10px 10px;
            .operation-btn{
                // width: 50px;
                margin-left: 10px;
            }
        }

        // 显示|隐藏
        &.show{
            transform: scale3d(1,1,1);
        }
        &.hide{
            transform: scale3d(0,0,0);
        }

        // 位置
        &.bottom{
            top: 100%;
            margin-top: 10px;
            .arrow{
                top: -4px;
            }
        }
        &.left{
            left: 0;
            .arrow{
                left: 15px;
            }
        }
        &.right{
            right: 0;
            transform-origin: 100% 0;
            .arrow{
                right: 15px
            }
        }
    }
    .mask{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
</style>