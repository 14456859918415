
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        value: {
            type: [String, Number],
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        placeholder: {
            type: String, 
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        maxlength: {
            type: Number,
            default: 0
        },
        max: {
            type: [Number, Date],
            default: 0
        },
        min: {
            type: [Number, Date],
            default: 0
        },
        autoSelect: {
            type: Boolean,
            default: true
        },
        prefix: {
            type: String,
            default: ''
        },
        suffix: {
            type: String,
            default: ''
        },
        clear: {
            type: Boolean,
            default: false
        },
        showPassword: {
            type: Boolean,
            default: false
        },
        round: {
            type: Boolean,
            default: false
        },
        msg: {
            type: String,
            default: ''
        },
        msgType: {
            type: String,
            default: 'error'
        }
    },
    emits: [ 'update:value', 'update:msg', 'change', 'focus', 'blur', 'keypress', 'enter' ],
    data(){
        return{
            inputVal: '',
            inputType: ''
        }
    },
    watch:{
        value(){
            this.inputVal = this.value;
        },
        type(){
            this.inputType = this.type;
        }
    },
    mounted(){
        this.inputVal = this.value;
        this.inputType = this.type;
    },
    methods: {
        handlerClear(){
            this.inputVal = '';
            this.$emit('update:value', '');
            this.$emit('update:msg', '');
        },
        handlerShowPassword(){
            this.inputType = this.inputType === 'text' ? 'password' : 'text';
        },
        handlerSelect(e: any){
            if(this.autoSelect) e.currentTarget.select();
        },
        handlerKeypress(e: any){
            this.$emit('keypress', e);
            if(e.code === 'Enter') this.$emit('enter', e);
        }
    }
})

export default class NInput extends Vue {}
