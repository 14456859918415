
import { Options, Vue } from 'vue-class-component';
import { Plus, CheckSmall, Close } from '@icon-park/vue-next';

@Options({
    components: {
        Plus, CheckSmall, Close
    },
    props: {
        action: {
            type: String,
            default: ''
        },
        headers: {
            type: Object,
            default: () => {}
        },
        width: {
            type: [String, Number],
            default: 100
        },
        height: {
            type: [String, Number],
            default: 100
        },
        iconSize: {
            type: [String, Number],
            default: 30
        },
        autoUpload: {
            type: Boolean,
            default: false
        },
        // oss配置
        region: {
            type: String,
            default: 'oss-cn-beijing'
        },
        bucket: {
            type: String,
            default: ''
        },
        src: {
            type: String,
            default: ''
        }
    },
    data(){
        return{
            iconColor: '#999',
            file: {},
            client: '',
            status: '',
            uploaded: '',
        }
    },
    emits: ['update:src'],
    methods: {
        change(e: any){
            this.file = e.target.files[0];
            // this.src = URL.createObjectURL(this.file);
            const reader = new FileReader(), that = this;
            reader.readAsDataURL(this.file);
            reader.onloadend = function() {
                // that.src = this.result;
                console.log(that)
                that.$emit('update:src', this.result);
            };
        },
        uploadToOss(file: any){
            const that = this;
            const fileName = this.file.name;
        },
    }
})

export default class NUpload extends Vue {}
