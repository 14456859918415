// import { RouteRecordRaw, RouteRecord } from 'vue-router'
// function formatRoutes(routes: any) {
//     let fmRoutes: Array<RouteRecordRaw> = []
//     routes.forEach((route: { component: string | object; children: Array<RouteRecordRaw>; path: string; redirect: string }) => {
//         route.component = () => import("@/views/" + route.component + ".vue");
//         if (route.children && route.children instanceof Array && route.children.length > 0) {
//             route.children = formatRoutes(route.children)
//         }
//         // fmRoutes.push(route)
//     })
//     return fmRoutes
// }

// 获取url中的参数
function queryParams(url: string){
    const temp = url.split('?')[1];
    return new URLSearchParams('?'+temp)
}

function blobToBase64(blob: any) {
  return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = (e: any) => {
            resolve(e.target.result);
        };
        // readAsDataURL
        fileReader.readAsDataURL(blob);
        fileReader.onerror = () => {
            reject(new Error('blobToBase64 error'));
        };
    });
}

export default {
    // formatRoutes,
    queryParams,
    blobToBase64
}