
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        checked: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            isChecked: false
        }
    },
    emits: ['update:checked']
})

export default class NCheckbox extends Vue {}
