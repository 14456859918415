
import { reactive, ref, toRefs } from 'vue';
import { Down } from '@icon-park/vue-next';
import NSelectOption from './SelectOption.vue';

export default {
    name: 'NSelect',
    components: {
        Down, NSelectOption
    },
    props: {
        width: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        },
        options: {
            type: Array,
            default: () => []
        },
        placeholder: {
            type: String, 
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        // 是否多选
        multiple: {
            type: Boolean,
            default: false
        },
        zIndex: {
            type: Number,
            default: 996
        }
    },
    setup(props: any, ctx: any){
        let show = ref(false),
            refOptions = reactive(props.options);

        function formatOptions(item: any, arr: any){
            arr.forEach((o: any) => {
                if(item == o) item.checked = true;
                else item.checked = false;
            })
        }
        function chooseItem(index: number){
            ctx.emit('choose', props.options[index]);
        }

        return {
            show,
            refOptions,
            chooseItem
        }
    }
}
