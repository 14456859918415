<template>
    <div class="n-dropdown" 
        :class="[$attrs.class]"
        :style="`
            width:${dropdownClient.width}px;
            height:${dropdownClient.height}px;
        `"
        @mouseenter="overShow"
        @click="clickShow"
    >
        <div 
            class="dropdown-link" 
            :class="show ? 'hover' : ''"
            ref="dropdownLink"
        >
            <slot name="link"></slot>
        </div>
        <div class="dropdown-menu" ref="dropdownMenu" 
            :class="[show ? 'show' : 'hide', position]"
            :style="`width:${width}`"
            @mouseleave="outHide"
            @click="show = false"
        >
            <div class="arrow"></div>
            <slot name="menu"></slot>
        </div>
    </div>
    <div class="n-dropdown-mask" v-if="show" @click="show = false"></div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        trigger: {
            type: String,
            default: 'hover'
        },
        width: {
            type: String,
            default: '100%'
        },
        position: {
            type: String,
            default: 'left bottom'
        }
    },
    data(){
        return {
            show: false,
            overMenu: false,
            dropdownClient: {
                width: '',
                height: ''
            }
        }
    },
    mounted(){
        this.dropdownClient.width = this.$refs['dropdownLink'].offsetWidth
        this.dropdownClient.height = this.$refs['dropdownLink'].offsetHeight
    },
    methods: {
        overShow(){
            if(this.trigger === 'hover') {
                this.show = true;
            }
        },
        clickShow(){
            if(this.trigger === 'click') this.show = !this.show;
        },
        outHide(){
            if(this.trigger === 'hover') {
                setTimeout(() => {
                    this.show = false;
                }, 300);
            }
        }
    }
})

export default class NDropdown extends Vue {}
</script>

<style lang="scss" scoped>
.n-dropdown{
    z-index: 2;
    position: relative;
    .dropdown-link{
        opacity: .5;
        &.hover{
            opacity: 1;
        }
    }
    .dropdown-menu{
        @extend .flex-column;
        position: absolute;
        padding: 5px;
        margin-top: 10px;
        border-radius: $radius;
        background-color: $white;
        box-shadow: $shadow;
        transform-origin: 0 0;
        .arrow{
            position: absolute;
            display: block;
            width: 10px;
            height: 10px;
            top: -4px;
            background: transparent;
            border-style: solid;
            border-width: 5px;
            transform: rotate(45deg);
            border-color: #fff transparent transparent #fff;
            box-shadow: -2px -2px 5px rgba(0,0,0,.06);
        }
        &.show{
            transform: scale3d(1,1,1);
        }
        &.hide{
            transform: scale3d(1,0,0);
        }
        // position
        &.left{
            left: 0;
            .arrow{
                left: 15px;
            }
        }
        &.top{
            top: 100%;
        }
        &.right{
            right: 0;
            .arrow{
                right: 15px
            }
        }
    }
}
.n-dropdown-mask{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>