
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        prefix: {
            type: String,
            default: ''
        },
        suffix: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: ''
        },
        shape: {
            type: String,
            default: 'round'
        },
        size: {
            type: String,
            default: ''
        }
    },
    emits: ['click'],
    methods: {
        handlerClick(){
            if(!this.disabled && !this.loading) this.$emit('click')
        }
    }
})

export default class NButton extends Vue {}
