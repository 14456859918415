export default {
    path: '/setting',
    meta: {
        title: '设置'
    },
    component: () => import('@/views/setting/Setting.vue'),
    children: [
        {
            path: '/setting/website',
            name: 'Website Setting',
            meta: {
                title: '网站设置'
            },
            component: () => import('@/views/setting/Website.vue')
        },{
            path: '/setting/contact',
            name: 'Contact Setting',
            meta: {
                title: '网站设置'
            },
            component: () => import('@/views/setting/Contact.vue')
        }
    ]
}