
import { Options, Vue } from 'vue-class-component';
import { Left, Right, DoubleLeft, DoubleRight } from '@icon-park/vue-next';

@Options({
    components: {
        Left, Right, DoubleLeft, DoubleRight
    },
    props: {
        date: {
            type: Object,
            default: {
                year: '',
                month: '',
                day: '',
                week: ''
            }
        },
        zIndex: {
            type: Number,
            default: 996
        }
    },
    data(){
        return {
            state: {
                year: '',
                month: '',
                day: '',
            },
            now: {},
            checkedDate: {},
            currentMonthArr: [],
            showPanel: false
        }
    },
    watch: {
        'state':{
            handler(nVal){
                this.currentMonthArr = this.getMonthArr(nVal.year, nVal.month);
            },
            deep: true
        },
        'date': {
            handler(){
                if(this.date.year === ''){
                    this.now = this.checkedDate = {
                        year: this.state.year,
                        month: this.state.month,
                        day: this.state.day
                    };
                } else {
                    this.checkedDate = this.state = this.date;
                }
            },
            deep: true
        }
    },
    computed: {
        checkedVal(){
            return `${this.checkedDate.year}年${this.checkedDate.month}月${this.checkedDate.day}日`;
        }
    },
    emits: ['change', 'update:date'],
    mounted(){
        let date = new Date();
        this.state = {
            year: date.getFullYear(),
            month: date.getMonth()+1,
            day: date.getDate()
        };
        
        
        this.chooseDate(this.state);
    },
    methods: {
        getWeek(date: string) {
            return new Date(Date.parse(date)).getDay();
        },
        getMonthArr(year: number, month: number){
            let days = new Date(year, month, 0).getDate(),
                preYear, preMonth, preDays, 
                sufYear, sufMonth, sufDays,
                preArr = [], sufArr = [],
                arr = [], 
                firstWeek = this.getWeek(`${year}-${month}-01`),
                lastWeek = this.getWeek(`${year}-${month}-${days}`),
                i;
            if(month === 12){
                preYear = year;
                preMonth = month-1;
                preDays = new Date(year, month-1, 0).getDate();

                sufYear = year+1;
                sufMonth = 1;
                sufDays = new Date(year+1, 1, 0).getDate();
            } else if(month === 1){
                preYear = year-1;
                preMonth = 12;
                preDays = new Date(year-1, 12, 0).getDate();

                sufYear = year;
                sufMonth = month+1;
                sufDays = new Date(year, month+1, 0).getDate();
            } else {
                preYear = sufYear = year;
                preMonth = month-1;
                sufMonth = month+1;
                preDays = new Date(year, month-1, 0).getDate();
                sufDays = new Date(year, month+1, 0).getDate();
            }

            // 需要补充的上个月的数组
            for(i = 0; i<firstWeek; i++){
                preArr.push({
                    year: preYear,
                    month: preMonth,
                    day: preDays-i,
                    week: this.getWeek(`${preYear}-${preMonth}-${preDays-i}`)
                })
            }

            // 需要补充的下个月的数组
            if(lastWeek !== 0){
                for(i = 0; i<6-lastWeek; i++){
                    sufArr.push({
                        year: sufYear,
                        month: sufMonth,
                        day: i+1,
                        week: this.getWeek(`${sufYear}-${sufMonth}-${i+1}`)
                    })
                }
            }

            for(i = 0; i<days; i++){
                arr.push({
                    year: year,
                    month: month,
                    day: i+1,
                    week: this.getWeek(`${year}-${month}-${i+1}`)
                })
            }
            
            return [
                ...preArr.reverse(),
                ...arr,
                ...sufArr
            ];
        },
        chooseDate(item: any){
            this.checkedDate = item;
            this.$emit('change', this.checkedDate);
            this.$emit('update:date', this.checkedDate);
        },
        nextMonth(){
            if(this.state.month === 12){
                this.state.month = 1;
                this.state.year += 1;
            } else this.state.month += 1;
        },
        preMonth(){
            if(this.state.month === 1){
                this.state.month = 12;
                this.state.year -= 1;
            } else this.state.month -= 1;
        }
    }
})

export default class NDatePicker extends Vue {}
