import { createStore } from 'vuex'

export default createStore({
	state: {
		accessToken: localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : sessionStorage.getItem('accessToken') ? sessionStorage.getItem('accessToken') : '',
        userInfo: {},
        subMenu: {},
	},
	mutations: {
        changeAccessToken(state, accessToken){
            state.accessToken = accessToken
        },
        changeUserInfo(state, userInfo){
            state.userInfo = userInfo
        },
        changeSubMenu(state, subMenu){
            state.subMenu = subMenu
        },
	},
	actions: {
	},
	modules: {
	}
})
