export default {
    path: '/users',
    redirect: '/users/users',
    meta: {
        title: '用户 && 管理员'
    },
    component: () => import('@/views/users/Layout.vue'),
    children: [
        {
            path: 'users',
            meta: {
                title: '用户管理'
            },
            component: () => import('@/views/users/Users.vue'),
        },
        {
            path: 'users/create',
            meta: {
                title: '新建用户'
            },
            component: () => import('@/views/users/UserCreate.vue')
        },{
            path: 'users/edit/:id',
            meta: {
                title: '编辑用户'
            },
            component: () => import('@/views/users/UserEdit.vue')
        },
        {
            path: 'admins',
            meta: {
                title: '管理员管理'
            },
            component: () => import('@/views/users/Admins.vue'),
        },
        {
            path: 'admins/create',
            meta: {
                title: '新建管理员'
            },
            component: () => import('@/views/users/AdminCreate.vue')
        },{
            path: 'admins/edit/:id',
            meta: {
                title: '编辑管理员'
            },
            component: () => import('@/views/users/AdminEdit.vue')
        }
    ]
}