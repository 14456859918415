
import { Options, Vue } from 'vue-class-component';
import { Close } from '@icon-park/vue-next';

@Options({
    components: {
        Close
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        duration: {
            type: Number,
            default: 5000
        },
        type: {
            type: String,
            default: ''
        },
        zIndex: {
            type: [String, Number],
            default: 9999
        },
        title: {
            type: String,
            default: '提示'
        },
        content: {
            type: String,
            default: '通知消息内容'
        }
    },
    emits: ['update:show'],
    data(){
        return{
            closeColor: '#999',
            canClose: true
        }
    },
    watch: {
        show(){
            if(this.show) this.notifyClose();
        }
    },
    methods: {
        notifyClose(){
            if(this.duration)
                setTimeout(() => {
                    if(this.canClose) this.$emit('update:show', false);
                }, this.duration);
        }
    }
})

export default class NNotification extends Vue {}
